import React from 'react';
import DataStore from "../../stores/DataStore";
import ImgAdd from '../images/IconAdd.png';
import ImgEdit from '../images/IconEdit.png';
import ImgSave from '../images/IconSave.png';
import ImgCancel from '../images/IconCancel.png';
import ImgDelete from '../images/IconDelete.png';
// BOOTSTRAP
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
const pageName = ' DataLoggerPanelM3.js';

export default class DataLoggerPanel extends React.Component {
    constructor() {
        super();
        this.state = {
            showStopReasons: true,
            job: {

            },
            machine: {

            },

        };
    }

    componentDidMount() {
        DataStore.on("getDLPanelDataComplete", this.getDLPanelDataComplete.bind(this));
        DataStore.on("machineStopped", this.machineStopped.bind(this));
        DataStore.getDLPanelData(this.props.terminal);
        setInterval(() => {
            DataStore.getDLPanelData(this.props.terminal);
        }, 10000);
    }

    componentWillUnmount() {
        DataStore.removeListener("getDLPanelDataComplete", this.getDLPanelDataComplete);
        DataStore.removeListener("machineStopped", this.machineStopped);
    }

    getDLPanelDataComplete(terminal, machine, job) {
        if (this.props.terminal == terminal) {
            //console.log(this.props.terminal + pageName + " - getDLPanelDataComplete ");
            console.log(job);
            //console.log(machine);
            this.setState({ job, machine });
        }
    }

    machineStopped(terminal) {
        if (this.props.terminal == terminal) {
            //console.log(this.props.terminal + pageName + " - machineStopped ");
            this.setState({ showStopReasons: true });
        }
    }


    handleButtonStopReason(reasonId) {
        console.log(reasonId);
        this.setState({ showStopReasons: false });
        DataStore.DLPanelStopReasonPressed(this.props.terminal);
    }

    handleButtonUnblock() {
        DataStore.DLPanelStopReasonPressed(this.props.terminal);
    }



    render() {
        var bgRunning = "danger";
        if (this.state.machine.running) bgRunning = "success";
        var pageInformations =
                <div className={"row"} style={{ padding: "20px" }}>
                    <div className={"col-6"}>
    
                        <Card className={"jobDetailDiv mb-3"} style={{ textColor: "black"}} bg={bgRunning} >
                            <Card.Header >
                                <div className={"row"} style={{ textAlign: "center" }}>
                                    <div style={{ textAlign: "center", width: '100%'}}>
                                        <h5><strong>{this.state.machine.name}</strong></h5>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                            <Table>
                                <thead>
                                </thead>
                                 <tbody>
                                    <tr>
                                        <td style={{ borderTop: "none" }}>STROJ BĚŽÍ:</td>
                                        <td style={{ borderTop: "none" }}>{this.state.machine.running ? "RUN" : "STOP"}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ borderTop: "black 1px solid" }}>CYKLŮ ZA MINUTU:</td>
                                        <td style={{ borderTop: "black 1px solid" }}>{this.state.machine.cyclesPerMinute}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ borderTop: "black 1px solid" }}>CYKLŮ CELKEM:</td>
                                        <td style={{ borderTop: "black 1px solid" }}>{this.state.machine.cyclesTotal}</td>
                                    </tr>
                                </tbody>
                            </Table>                            
                            </Card.Body>
                        </Card>
                        <Button style={{ width: "100%", height: "50px", marginBottom: "10px", backgroundColor: "#a8e31e" }} size="lg" onClick={() => this.handleButtonUnblock() } variant="dark" >ODBLOKOVAT START</Button>


                    </div>

                    <div className={"col-6"}>
                        <Card className={"jobDetailDiv mb-3"} style={{textColor: "black"}} bg="light" >
                            <Card.Header >
                                <div className={"row"} style={{ textAlign: "center" }}>
                                    <div style={{ textAlign: "center", width: '100%'}}>
                                        <h5><strong>{this.state.job.job_name }</strong></h5>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <h5 className="card-title" style={{ fontWeight: 800}} >DETAIL</h5>
                                <div style={{ textAlign: "center", width: '100%'}}>
                                <div><span style={{ float: "left" }} ><strong>Recept: </strong></span><span style={{ float: "right" }} > {this.state.job.recipe_name}</span></div>
                                <div><span style={{ float: "left" }} ><strong>Forma: </strong></span><span style={{ float: "right" }} > {this.state.job.tool_name}</span></div>
                                <div><span style={{ float: "left" }} ><strong>Materiál: </strong></span><span style={{ float: "right" }} > {this.state.job.material_type + ' ' + this.state.job.material_width + 'mm x ' + this.state.job.material_thickness + 'µm'}</span></div>
                                <div><span style={{ float: "left" }} ><strong>Množství: </strong></span><span style={{ float: "right" }} > {this.state.job.job_parts}</span></div>
                                <div><hr/></div>
                                <div><span style={{ float: "left" }} ><strong>Plán:</strong></span><span style={{ float: "right" }} >{plannedDate}</span></div>
                                <div><span style={{ float: "left" }} ><strong>Start:</strong></span><span style={{ float: "right" }} >{startDate}</span></div>
                                <div><span style={{ float: "left" }} ><strong>Konec:</strong></span><span style={{ float: "right" }} >{finishDate}</span></div>
                                </div>
                            </Card.Body>
    
                        </Card>
                    </div>

                </div>
        var pageStopReasons =                 
                <div style={{ height: "100%" }}>
                    <div className={"row"} >
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#a8e31e" }} size="lg" onClick={() => this.handleButtonStopReason(2)} variant="dark" >VÝMĚNA ROLE</Button>
                        </div>
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#e6744e" }} size="lg" onClick={() => this.handleButtonStopReason(13)} variant="dark" >PROBLÉM S MATERIÁLEM</Button>
                        </div>
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#4ecae6" }} size="lg" onClick={() => this.handleButtonStopReason(1)} variant="dark" >VÝMĚNA FORMY</Button>
                        </div>
                    </div>
                    <div className={"row"} >
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#a8e31e" }} size="lg" onClick={() => this.handleButtonStopReason(19)} variant="dark" >KALIBRACE</Button>
                        </div>
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#e6744e" }} size="lg" onClick={() => this.handleButtonStopReason(23)} variant="dark" >PROBLÉM STROJE</Button>
                        </div>
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#4ecae6" }} size="lg" onClick={() => this.handleButtonStopReason(11)} variant="dark" >OPRAVA \ ÚDRŽBA</Button>
                        </div>
                    </div>
                    <div className={"row"} >
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#a8e31e" }} size="lg" onClick={() => this.handleButtonStopReason(25)} variant="dark" >SMART KAMERA STOP</Button>
                        </div>
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#e6744e" }} size="lg" onClick={() => this.handleButtonStopReason(16)} variant="dark" >NEVÍM</Button>
                        </div>
                        <div className={"col-4"} >
                            <Button style={{ width: "100%", height: "150px", marginBottom: "10px", backgroundColor: "#4ecae6" }} size="lg" onClick={() => this.handleButtonStopReason(30)} variant="dark" >KONEC SMĚNY</Button>
                        </div>
                    </div>
                </div>


        var jobStatus = "";
        var plannedDate = "";
        var startDate = "";
        var finishDate = "";
        return (
            <div>
                {this.state.showStopReasons ? pageStopReasons : pageInformations }

            </div>
        );
    }
}
