import React from 'react';
import DataStore from "../stores/DataStore";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, globalHistory } from '@reach/router'
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ImgFlagEN from './images/FlagEN.png';
import ImgFlagNL from './images/FlagNL.png';
import ImgFlagPL from './images/FlagPL.png';

export default class Menu extends React.Component {

    constructor(props) {
        super(props);
        var str = globalHistory.location.pathname.substr(1, globalHistory.location.pathname.length);
        var terminal = str.substr(0, str.indexOf("/"));
        var page = str.substr(str.indexOf("/") + 1, str.length);
        str = str.substr(str.indexOf("/") + 1, str.length);
        if (str.indexOf("/") != -1) page = str.substr(0, str.indexOf("/"));



        console.log("Menu.js - constructor() terminal: " + terminal + " page: " + page);



        this.toggle = this.toggle.bind(this);
        this.state = {
            terminal: terminal,
            page: page,
            loggedUser: {},
            logoutInterval: 0,
            logoutCountdownCounter: 300,
            showLogoutCountdown: false,
            dropdownOpen: false,
            menuDate: "",
        };

        setInterval(() => {
            var dt = new Date();
            var weekCycle = this.getWeek(dt) % 4;
            if (weekCycle === 0) weekCycle = 4;
            //var menuWeek = 'TÝDEN ' + this.getWeek(dt) + '(' + weekCycle + ')';
            var menuWeek = 'TÝDEN ' + this.getWeek(dt);
            var menuDate = dt.getDate() + '.' + (dt.getMonth() + 1) + '.' + dt.getFullYear();
            var menuTime = ((dt.getHours() <= 9) ? "0" : "") + dt.getHours() + ':' + ((dt.getMinutes() <= 9) ? "0" : "") + dt.getMinutes() + ':' + ((dt.getSeconds() <= 9) ? "0" : "") + dt.getSeconds();
            this.setState({ menuWeek: menuWeek, menuDate: menuDate, menuTime: menuTime });

            // LOGOUT
            if (this.state.logoutCountdownCounter > 0) {
                this.setState({ logoutCountdownCounter: (this.state.logoutCountdownCounter - 1) });
                if (this.state.logoutCountdownCounter == 0) {
                    this.setState({ showLogoutCountdown: false });
                }
            }

        }, 1000);
        this.handleInfoModalPress = this.handleInfoModalPress.bind(this)
        this.handleInfoModalRelease = this.handleInfoModalRelease.bind(this)
        this.infoModalTaggle = this.infoModalTaggle.bind(this)
    };



    componentDidMount() {
        DataStore.on("checkRfidCodeComplete", this.checkRfidCodeComplete.bind(this));
        DataStore.on("checkPinCodeComplete", this.checkPinCodeComplete.bind(this));
        DataStore.on("logout", this.logout.bind(this));
    }

    componentWillUnmount() {
        DataStore.removeListener("checkRfidCodeComplete", this.checkRfidCodeComplete);
        DataStore.removeListener("checkPinCodeComplete", this.checkPinCodeComplete);
        DataStore.removeListener("logout", this.logout);
    }

    // USER PERMISSION
    checkRfidCodeComplete(terminal, user) {
        if (this.state.terminal == terminal) {
            console.log("Menu.js - checkRfidCodeComplete terminal: " + this.props.terminal + " name: " + user.user_name);
            //console.log(user);
            this.setState({ loggedUser: user });
            this.setState({ logoutCountdownCounter: 300 });
            this.setState({ showLogoutCountdown: true });
        }
    }

    checkPinCodeComplete(terminal, user) {
        if (this.state.terminal == terminal) {
            console.log(terminal + " Menu.js - checkPinCodeComplete ");
            console.log(user);
            if (typeof user.user_name != 'undefined') {
                this.setState({ loggedUser: user });
                this.setState({ logoutCountdownCounter: 300 });
                this.setState({ showLogoutCountdown: true });
            }
        }
    }

    logoutPress() {
        DataStore.logout(this.state.terminal);
    }

    logout(terminal) {
        if (this.state.terminal == terminal) {
            console.log(this.state.terminal + " Menu.js - logout ");
            this.setState({ iButton: "" });
            this.setState({ loggedUser: {} });
            this.setState({ showLogoutCountdown: false });
        }
    }


    handleInfoModalPress() {
        this.buttonPressTimer = setTimeout(() => this.infoModalTaggle(), 5000);
    }

    handleInfoModalRelease() {
        clearTimeout(this.buttonPressTimer);
    }

    infoModalTaggle() {
        var newState = !this.state.infoModal;
        this.setState({ infoModal: newState });
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }


    // Returns the four-digit year corresponding to the ISO week of the date.
    getWeekYear(dt) {
        var date = new Date(dt.getTime());
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
    }

    // Returns the ISO week of the date.
    getWeek(dt) {
        var date = new Date(dt.getTime());
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 -
            3 + (week1.getDay() + 6) % 7) / 7);
    }

    clickFlagEN() {}

    clickFlagNL() {}

    clickFlagPL() {}

    clickRestart() {
        DataStore.restartProductionTerminal();
    }

    clickShift() {
        DataStore.shiftWindowShow(this.state.terminal);
    }

    clickLogin() {
        DataStore.clickLogin(this.state.terminal);
    }

    clickMenu() {
        this.setState({ logoutCountdownCounter: 0 });
        this.setState({ showLogoutCountdown: false });

    }

    render() {
        var menu = '';
        var header = 'AUTOMATION SYSTEM';
        var logoutInfo;
        if (this.state.showLogoutCountdown) logoutInfo = <Button style={{ marginRight: "10px" }} variant="secondary" onClick={this.logoutPress.bind(this)} ><h6 style={{  lineHeight: "0.8" }}>{"LOGGED AS: " + this.state.loggedUser.user_name}</h6><h6 style={{ lineHeight: "0.8" }}>{"AUTO LOGOUT IN: " + this.state.logoutCountdownCounter + " s"}</h6></Button>
        if (globalHistory.location.pathname.indexOf('/terminalOB') != -1) {
            menu =
                <DropdownMenu style={{ left: "-50px" }}>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/dataloggerDashboardPopinecka" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD POPINECKA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/dataloggerDashboardMilba" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD MILBA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/jobs" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >JOBY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/jobsHistory" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >HISTORIE JOBŮ</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/formy" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >FORMY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/receptury" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >RECEPTURY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/material" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >MATERIÁL</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/cameras" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >KAMERY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalOB/eventLogs" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >LOGS</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.clickLogin.bind(this)} >LOGIN</DropdownItem>
            </DropdownMenu>
        }



        if (globalHistory.location.pathname.indexOf('/terminalPopineckaTestStation') != -1) {
            menu =
                <DropdownMenu style={{ left: "-50px" }}>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaTestStation/dataloggerDashboardPopinecka" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD POPINECKA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaTestStation/jobs" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >JOBY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaTestStation/jobsHistory" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >HISTORIE JOBŮ</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaTestStation/formy" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >FORMY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaTestStation/receptury" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >RECEPTURY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaTestStation/material" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >MATERIÁL</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.clickLogin.bind(this)} >LOGIN</DropdownItem>
            </DropdownMenu>
        }



        if (globalHistory.location.pathname.indexOf('/terminalPopineckaDLScreenOffice') != -1) {
            menu =
                <DropdownMenu style={{ left: "-50px" }}>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaDLScreenOffice/dataloggerDashboardAll" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalPopineckaDLScreenOffice/cameras" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >KAMERY</Link></DropdownItem>
            </DropdownMenu>
        }


        if (globalHistory.location.pathname.indexOf('/terminalMilbaTestStation') != -1) {
            menu =
                <DropdownMenu style={{ left: "-50px" }}>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalMilbaTestStation/dataloggerDashboardMilba" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD MILBA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalMilbaTestStation/jobs" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >JOBY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalMilbaTestStation/jobsHistory" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >HISTORIE JOBŮ</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalMilbaTestStation/formy" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >FORMY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalMilbaTestStation/receptury" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >RECEPTURY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalMilbaTestStation/material" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >MATERIÁL</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.clickLogin.bind(this)} >LOGIN</DropdownItem>
            </DropdownMenu>
        }




        if (globalHistory.location.pathname.indexOf('/terminalIvoHainzel') != -1) {
            menu =
                <DropdownMenu style={{ left: "-50px" }}>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/dataloggerDashboardPopinecka" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD POPINECKA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/dataloggerDashboardMilba" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD MILBA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/jobs" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >JOBY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/jobsHistory" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >HISTORIE JOBŮ</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/formy" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >FORMY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/receptury" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >RECEPTURY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/material" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >MATERIÁL</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalIvoHainzel/cameras" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >KAMERY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.clickLogin.bind(this)} >LOGIN</DropdownItem>
            </DropdownMenu>
        }


        if (globalHistory.location.pathname.indexOf('/terminalJakubAlex') != -1) {
            menu =
                <DropdownMenu style={{ left: "-50px" }}>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/dataloggerDashboardPopinecka" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD POPINECKA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/dataloggerDashboardMilba" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD BUNIČITÁ</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/jobs" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >JOBY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/jobsHistory" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >HISTORIE JOBŮ</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/formy" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >FORMY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/receptury" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >RECEPTURY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/material" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >MATERIÁL</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJakubAlex/cameras" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >KAMERY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.clickLogin.bind(this)} >LOGIN</DropdownItem>
            </DropdownMenu>
        }


        if (globalHistory.location.pathname.indexOf('/terminalJaroslavKocvara') != -1) {
            menu =
                <DropdownMenu style={{ left: "-50px" }}>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJaroslavKocvara/dataloggerDashboardMilba" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >DASHBOARD MILBA</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJaroslavKocvara/jobs" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >JOBY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJaroslavKocvara/jobsHistory" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >HISTORIE JOBŮ</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJaroslavKocvara/formy" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >FORMY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJaroslavKocvara/receptury" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >RECEPTURY</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem ><Link to="/terminalJaroslavKocvara/material" onClick={this.clickMenu.bind(this)} replace style={{ display: "block"}} >MATERIÁL</Link></DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.clickLogin.bind(this)} >LOGIN</DropdownItem>
            </DropdownMenu>
        }





        var str = globalHistory.location.pathname;
        var firstIndex = str.indexOf("/");
        var lastIndex = str.lastIndexOf("/");
        var terminalName = str.substr(0, lastIndex);
        terminalName = terminalName.replace('/terminal', '');
        terminalName = terminalName.toUpperCase();

        var page = str.substring(lastIndex + 1, str.length);
        var page2 = '';
        var prevFindIndex = 0;
        for (var i = 0; i < page.length; i++) {
            var bigLetter = page[i].toUpperCase();
            if (page[i] == bigLetter) {
                page2 = page2 + page.substring(prevFindIndex, i).toUpperCase() + ' ';
                prevFindIndex = i;
            }
            if (i == page.length - 1) page2 = page2 + page.substring(prevFindIndex, page.length).toUpperCase();
        }

        header = 'AUTOMATION SYSTEM  -  ' + terminalName + '  -  ' + page2;

        var display = "";
        if (this.state.page == "jobReport") display = "none";

        var elementTop = <div></div>;
        if (this.state.terminal != "terminalM3" && this.state.terminal != "terminalM6" && this.state.terminal != "terminalM7" && this.state.terminal != "terminalM8") {
            elementTop =                   <div>
            <div className="row" >
       <div id="menu-date" className={"col-3"}>
        <Badge  variant="dark" style={{marginTop: "15px", marginLeft: "15px" }}>
            <h5 style={{ float: 'left', margin: '5px' }}><Badge  variant="secondary" >{this.state.menuWeek}</Badge></h5>
            <h5 style={{ float: 'left', margin: '5px' }}><Badge  variant="secondary" >{this.state.menuDate}</Badge></h5>
            <h5 style={{ float: 'left', margin: '5px' }}><Badge  variant="secondary" >{this.state.menuTime}</Badge></h5>
        </Badge>
       </div>
       
       <div className={"col-5"}>
         <h4 className=" p-3 text-center"  onMouseDown={this.handleInfoModalPress} onMouseUp={this.handleInfoModalRelease} >{header}</h4>
       </div>
       
       <div className="col-4 text-right">
        {logoutInfo}
        <UncontrolledButtonDropdown className=" p-3" >
          <DropdownToggle caret size='md' style={{paddingRight: "50px", paddingLeft: "50px"}}>
            MENU
          </DropdownToggle>
            {menu}
        </UncontrolledButtonDropdown>

       </div>

      </div>
      
        <Modal show={this.state.infoModal} onHide={this.infoModalTaggle} dialogClassName="subtask-modal" style={{color: "#222c40"}}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.subtaskSubject}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="exampleForm.ControlText">
                    <Form.Label>MESSAGE:</Form.Label>
                    <Form.Control as="textarea" size="lg" rows="3" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button size="lg" variant="secondary" onClick={this.infoModalTaggle}>
                CLOSE
              </Button>
            </Modal.Footer>
        </Modal>
      </div>

      
      
        }

        return (
            <div id="top-menu" style={{ display, color: "white"}} >
                {elementTop}
            </div>



        );
    }
}
