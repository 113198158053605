import React from 'react';
import DataStore from "../../stores/DataStore";
import dateFormat from 'dateformat';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Chart from 'react-apexcharts'

export default class ModalJobReport extends React.Component {
    constructor(props) {
        super(props);
        this.modalTaggle = this.modalTaggle.bind(this);
        this.state = {
            oeePercent: 0,
            showModalInOperation: false,

        }
    }

    componentDidMount() {}
    componentWillUnmount() {}

    componentDidUpdate(prevProps) {
        if (this.props.reportData !== prevProps.reportData) {

        }
    }

    modalShow() {

    }


    modalTaggle() {
        console.log("ModalJobReport.js - modalTaggle ");
        this.props.modalTaggle();
    }


    oeePercentChange(e) {
        this.setState({ oeePercent: e.target.value });
    }


    render() {
            var t = this;
        var seriesData1 = [];
        var seriesData2 = [];
        var seriesData3 = [];
        var seriesData4 = [];
        var seriesData5 = [];
        var seriesData6 = [];
        var xaxis = { categories: [], tickPlacement: 'on', labels: { rotate: -20, } };

        var colorSerie1 = '#afffdc';
        var colorSerie2 = '#f4ddff';
        var colorSerie3 = '#6cb3fd';
        var colorSerie4 = '#ff8e4c';
        var colorSerie5 = '#FFFF00';
        var colorSerie6 = '#FF0000';

        var expectedCycles = 0;
        var expectedCyclesFormula = 'Expected machine cycles when machine is running = Run minutes * Recipe max cycles = ';
        var actualCycles = 0;

        var availabilityPercent = 0;
        var availableTimeHours = 0;
        var availableTimeMinutes = 0;
        var runMinutesHours = 0;
        var runMinutesMinutes = 0;

        var materialConsumption = 0;
        var materialConsumptionFormula = 'Sum of all material used = ';
        //var materials = this.props.reportData.materials

        var materialWinderScrap = 0;
        var performancePercent = 0;

        var jobStart = this.props.job.ActualStart;
        var jobFinish = this.props.job.ActualFinish;

        var jobPalletsMade = 0;
        var jobPalletsPlanned = 0;
        var jobProductsMade = 0;

        var productsWeight = 0;
        var productsWeightFormula = 'Expected material to be used including the scrap = Parts made * Weight per part incl waste = ';

        var oeePercent = 0;
        var qualityPercent = 0;
        var powerConsumption = 0;
        var powerConsumptionPerPiece = 0;
        var powerConsumptionPerGram = 0;
        var coolingConsumption = 0;
        var coolingConsumptionPerPiece = 0;
        var coolingConsumptionPerGram = 0;




            var stopReasonsMinutes = 0;
            var stopReasonsCount = 0;


        if (this.props.modal) {
/*
            materials.forEach(function(material, index) {
                materialConsumptionFormula += Math.abs(material.SumQty);
                if (index + 1 < materials.length) materialConsumptionFormula += ' + ';
                if (index + 1 == materials.length) materialConsumptionFormula += ' = ';
                if (index + 1 == materials.length) materialConsumptionFormula += Math.round(materialConsumption / 1000);
                if (index + 1 == materials.length) materialConsumptionFormula += ' kg';
            });
*/



            jobStart = String(jobStart).replace('T', ' ');
            jobStart = String(jobStart).replace('.000Z', '');
            jobFinish = String(jobFinish).replace('T', ' ');
            jobFinish = String(jobFinish).replace('.000Z', '');
/*
            this.props.reportData.products.forEach(function(product) {
                if (!product.IsCancelled) {
                    if (product.IsActioned) {
                        jobProductsMade += Math.round(product.ExpectedQTY * 1000);
                        jobPalletsMade++;
                    }
                    jobPalletsPlanned++;
                }
            });
*/

            // IF OBJECT EXISTS
            if (this.props.reportData.samples.length > 0) {
                jobProductsMade = Math.round((this.props.reportData.samples[this.props.reportData.samples.length - 1].sample_cycles_all - this.props.reportData.samples[0].sample_cycles_all) * this.props.job.tool_parts_count);
                jobPalletsMade = jobProductsMade / this.props.reportData.job.recipe_parts_per_package
                materialConsumption = jobProductsMade * this.props.job.recipe_parts_per_kilogram / 1000;
                productsWeight = materialConsumption;
                availabilityPercent = Number(this.props.reportData.availability.percent).toFixed(0);
                availableTimeHours = Math.floor(this.props.reportData.availability.availableMinutes / 60);
                availableTimeMinutes = Math.round(this.props.reportData.availability.availableMinutes % 60);
                var availableMinutes = this.props.reportData.availability.availableMinutes - this.props.reportData.availability.stopShiftMinutes;
                runMinutesHours = Math.floor(this.props.reportData.availability.runMinutes / 60);
                runMinutesMinutes = Math.round(this.props.reportData.availability.runMinutes % 60);
                var norma = Math.round(this.props.reportData.job.recipe_parts_per_shift / 7.5 / 60 * 10 / this.props.reportData.job.tool_parts_count) / 10
                expectedCycles = Math.round(this.props.reportData.availability.runMinutes * norma);
                expectedCyclesFormula += this.props.reportData.availability.runMinutes;
                expectedCyclesFormula += ' * ';
                expectedCyclesFormula += norma;
                expectedCyclesFormula += ' = ';
                expectedCyclesFormula += expectedCycles;
                actualCycles = Math.round(this.props.reportData.performance.actualCycles);
                productsWeightFormula += jobProductsMade;
                productsWeightFormula += ' * ';
                //productsWeightFormula += this.props.reportData.recipe.weightIncludeWaste;
                productsWeightFormula += ' = ';
                productsWeightFormula += productsWeight;
                productsWeightFormula += ' g';
                if (materialConsumption != 0) qualityPercent = Number(100 / materialConsumption * productsWeight).toFixed(0);
                performancePercent = Math.round(100 / expectedCycles * actualCycles);
                oeePercent = Number(availabilityPercent * performancePercent * qualityPercent / 10000).toFixed(0);
                powerConsumption = Math.round((this.props.reportData.samples[this.props.reportData.samples.length - 1].sample_electrometer_total - this.props.reportData.samples[0].sample_electrometer_total) / 1000);
                powerConsumptionPerPiece = Number((this.props.reportData.samples[this.props.reportData.samples.length - 1].sample_electrometer_total - this.props.reportData.samples[0].sample_electrometer_total) / jobProductsMade).toFixed(2);
                //powerConsumptionPerGram = Number(powerConsumptionPerPiece / this.props.reportData.recipe.weight).toFixed(3);

                // 1 MWh = 3,6 GJ; 1 GJ = 0,2778 MWh.
                console.log(this.props.reportData.samples[0].sample_flowmeter_energy_total);
                console.log(this.props.reportData.samples[this.props.reportData.samples.length - 1].sample_flowmeter_energy_total);
                coolingConsumption = Math.round((this.props.reportData.samples[this.props.reportData.samples.length - 1].sample_flowmeter_energy_total - this.props.reportData.samples[0].sample_flowmeter_energy_total) / 3.6);
                coolingConsumptionPerPiece = Number(coolingConsumption / jobProductsMade * 1000).toFixed(2);
                //coolingConsumptionPerGram = Number(coolingConsumptionPerPiece / this.props.reportData.recipe.weight).toFixed(3);

                var jobId = 0;
                if (typeof this.props.job != 'undefined') {
                    if (typeof this.props.job.JobID != 'undefined') {
                        if (typeof this.props.job.JobID[0] != 'undefined') {
                            jobId = this.props.job.JobID[0];
                        }
                        else {
                            jobId = this.props.job.JobID;
                        }
                    }
                }
                var recipeName = "";
            }
            if (typeof this.props.reportData.recipe != 'undefined') {
                recipeName = this.props.reportData.recipe.name;
            }
            console.log(this.props.reportData.recipe);
            var tempPercent = Math.tanh((100 - oeePercent) / 20) * 100;
            if (tempPercent > 100) tempPercent = 100;
            if (tempPercent < 0) tempPercent = 0;
            var oeeRgb = 'rgb(' + Math.round(tempPercent * 2.55) + ',' + (Math.round((100 - tempPercent) * 2.55)) + ',0)';
            tempPercent = Math.tanh((100 - availabilityPercent) / 20) * 100;
            if (tempPercent > 100) tempPercent = 100;
            if (tempPercent < 0) tempPercent = 0;
            var availabilityRgb = 'rgb(' + Math.round(tempPercent * 2.55) + ',' + (Math.round((100 - tempPercent) * 2.55)) + ',0)';
            tempPercent = Math.tanh((100 - performancePercent) / 20) * 100;
            if (tempPercent > 100) tempPercent = 100;
            if (tempPercent < 0) tempPercent = 0;
            var performanceRgb = 'rgb(' + Math.round(tempPercent * 2.55) + ',' + (Math.round((100 - tempPercent) * 2.55)) + ',0)';
            tempPercent = Math.tanh((100 - qualityPercent) / 20) * 100;
            if (tempPercent > 100) tempPercent = 100;
            if (tempPercent < 0) tempPercent = 0;
            var qualityRgb = 'rgb(' + Math.round(tempPercent * 2.55) + ',' + (Math.round((100 - tempPercent) * 2.55)) + ',0)';
            //console.log(rgb);




            // COPY DATA TO THE CHART
            this.props.reportData.samples.forEach(function(sample, index) {
                xaxis.categories.push(sample.sample_dt_saved);
                seriesData1.push(Math.round(sample.sample_machine_run));
                // SHIFT STOP
                if (sample.sample_stop_reason_id != 30) seriesData2.push(0);
                if (sample.sample_stop_reason_id == 30) seriesData2.push(1);
                seriesData3.push(sample.sample_cycles_all);
                seriesData4.push(sample.sample_cycles_average);
                //seriesData5.push(sample.recipe_max_cycles);
                seriesData5.push(norma);
                seriesData6.push(sample.sample_electrometer_total);
            });

            var series = [{
                name: 'Stroj RUN',
                type: 'area',
                data: seriesData1
            }, {
                name: 'Stroj cykly',
                type: 'line',
                data: seriesData3
            }, {
                name: 'Stroj cykly za minutu',
                type: 'line',
                data: seriesData4
            }, {
                name: 'Recept cykly za minutu',
                type: 'line',
                data: seriesData5
            }, {
                name: 'Spotřeba el. energie',
                type: 'line',
                data: seriesData6
            }];

            var options = {

                chart: {
                    id: 'apexchart-example',
                    height: 350,
                    type: 'line',
                    stacked: false
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [0, 2, 2, 2, 2]
                },
                colors: [colorSerie1, colorSerie3, colorSerie4, colorSerie5, colorSerie6],
                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },
                title: {
                    text: '',
                    align: 'left',
                    offsetX: 110
                },
                xaxis: xaxis,
                yaxis: [{
                        seriesName: 'serie1',
                        show: false,
                        opposite: true,
                        tickAmount: 1,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: colorSerie1
                        },
                        labels: {
                            style: {
                                colors: colorSerie1,
                            },
                            formatter: (value) => { if (value == 0) return "STROJ STOP"; if (value == 1) return "STROJ RUN" },
                        },
                        title: {
                            text: "Stroj RUN",
                            style: {
                                color: colorSerie1,
                            }
                        },
                    },
                    {
                        seriesName: 'serie3',
                        tickAmount: 5,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: colorSerie3,
                        },
                        labels: {
                            style: {
                                colors: colorSerie3,
                            },
                            formatter: (value) => { return Math.round(value) },
                        },
                        title: {
                            text: "Cykly total",
                            style: {
                                color: colorSerie3,
                            }
                        },
                    },
                    {
                        seriesName: 'serie4',
                        tickAmount: 5,
                        max: 25,
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#FEB019'
                        },
                        labels: {
                            style: {
                                colors: '#FEB019',
                            },
                            formatter: (value) => { return Math.round(value) },
                        },
                        title: {
                            text: "Cykly za minutu",
                            style: {
                                color: '#FEB019',
                            }
                        }
                    },
                    {
                        seriesName: 'serie5',
                        show: false,
                        tickAmount: 5,
                        max: 25,
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#FEB019'
                        },
                        labels: {
                            style: {
                                colors: '#FEB019',
                            },
                            formatter: (value) => { return Math.round(value) },
                        },
                        title: {
                            text: "Cykly za minutu",
                            style: {
                                color: '#FEB019',
                            }
                        }
                    },
                    {
                        seriesName: 'serie6',
                        show: false,
                        labels: {
                            formatter: (value) => { return Number(value / 1000).toFixed(1) },
                        },
                    },

                ],
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            };

            var seriesAvailability = [availabilityPercent];
            var optionsAvailability = {
                chart: {},
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                            name: {
                                fontSize: '16px',
                                color: '#000000',
                                offsetY: -110
                            },
                            value: {
                                offsetY: 0,
                                fontSize: '22px',
                                color: '#000000',
                                formatter: function(val) {
                                    return val + "%";
                                }
                            }
                        },
                        track: {
                            background: "#fefefe",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                    }
                },
                colors: [availabilityRgb],
                stroke: {
                    lineCap: 'round'
                },
                labels: ['DOSTUPNOST'],
            };

            var seriesPerformance = [performancePercent];
            var optionsPerformance = {
                chart: {},
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                            name: {
                                fontSize: '16px',
                                color: '#000000',
                                offsetY: -110
                            },
                            value: {
                                offsetY: 0,
                                fontSize: '22px',
                                color: '#000000',
                                formatter: function(val) {
                                    return val + "%";
                                }
                            }
                        },
                        track: {
                            background: "#fefefe",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                    }
                },
                colors: [performanceRgb],
                stroke: {
                    lineCap: 'round'
                },
                labels: ['VÝKON'],
            };


            var seriesQuality = [qualityPercent];
            var optionsQuality = {
                chart: {},
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                            name: {
                                fontSize: '16px',
                                color: '#000000',
                                offsetY: -110
                            },
                            value: {
                                offsetY: 0,
                                fontSize: '22px',
                                color: '#000000',
                                formatter: function(val) {
                                    return val + "%";
                                }
                            }
                        },
                        track: {
                            background: "#fefefe",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                    }
                },
                colors: [qualityRgb],
                stroke: {
                    lineCap: 'round'
                },
                labels: ['KVALITA'],
            };



            var seriesOee = [oeePercent];
            var optionsOee = {
                chart: {},
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                            name: {
                                fontSize: '16px',
                                color: '#000000',
                                offsetY: -110
                            },
                            value: {
                                offsetY: 0,
                                fontSize: '22px',
                                color: '#000000',
                                formatter: function(val) {
                                    return val + "%";
                                }
                            }
                        },
                        track: {
                            background: "#fefefe",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                    }
                },
                colors: [oeeRgb],
                stroke: {
                    lineCap: 'round'
                },
                labels: ['OEE'],

            };


            this.props.reportData.stopReasons.map((reason, index) => {
                stopReasonsMinutes += reason.stopReasonMinutes;
                stopReasonsCount += reason.objects.length;
            });
        }

        //console.log(xaxis);
        return (
            <div className={"text-blue-white"}>
                <Modal show={this.props.modal} onHide={this.modalTaggle.bind(this)} onShow={this.modalShow.bind(this)} dialogClassName="report-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>JOB REPORT - Generováno {dateFormat(new Date(), "dd.mm.yyyy HH:MM")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row"  >
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 " style={{  paddingLeft: "0px", paddingRight: "0px" }}>
                                        <Card style={{  margin: "5px" }}>
                                            <Card.Header>
                                                <h5>SPECIFIKACE JOBU</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Table size="sm">
                                                  <tbody>
                                                  
                                                    <tr>
                                                      <td>Název Formy:</td>
                                                      <td>{typeof this.props.reportData.job != 'undefined' ? this.props.reportData.job.tool_name : null}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Název Jobu:</td>
                                                      <td>{typeof this.props.reportData.job != 'undefined' ? this.props.reportData.job.job_name : null}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Job ID:</td>
                                                      <td>{typeof this.props.reportData.job != 'undefined' ? this.props.reportData.job.job_id : null}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Datum Startu:</td>
                                                      <td>{typeof this.props.reportData.job != 'undefined' ? dateFormat(this.props.reportData.job.job_dt_start, "dd.mm.yyyy HH:MM") : null}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Datum Konce:</td>
                                                      <td>{typeof this.props.reportData.job != 'undefined' ? dateFormat(this.props.reportData.job.job_dt_stop, "dd.mm.yyyy HH:MM") : null}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Plánovaných Kartónů:</td>
                                                      <td>{typeof this.props.reportData.job != 'undefined' ? this.props.reportData.job.job_boxes : null}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Vyrobeno kartónů:</td>
                                                      <td>{jobPalletsMade}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Vyrobeno produktů:</td>
                                                      <td>{jobProductsMade}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Spotřeba el. energie na Job:</td>
                                                      <td>{powerConsumption} kWh</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Spotřeba el. energie na 1 kus:</td>
                                                      <td>{powerConsumptionPerPiece} Wh</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Spotřeba el. energie na 1 gram:</td>
                                                      <td>{powerConsumptionPerGram} Wh/g</td>
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                                
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-6 " style={{  paddingLeft: "0px", paddingRight: "0px" }}>
                                        <Card style={{  margin: "5px" }}>
                                            <Card.Header>
                                                <h5>TABULKA DŮVODŮ STOPU</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>ID</strong></td>
                                                            <td><strong>Důvod</strong></td>
                                                            <td><strong>Počet</strong></td>
                                                            <td><strong>Čas</strong></td>
                                                            <td><strong>Ztráta</strong></td>
                                                        </tr>
                                                        {
                                                            this.props.reportData.stopReasons.map((reason, index) => {
                                                                return <tr key={index}>
                                                                    <td>{reason.stopReasonId}</td>
                                                                    <td>{reason.stopReasonName}</td>
                                                                    <td>{reason.objects.length}</td>
                                                                    <td>{Math.floor(reason.stopReasonMinutes / 60)} hodin {reason.stopReasonMinutes % 60} minut</td>
                                                                    <td>{Number(100 / availableMinutes * reason.stopReasonMinutes).toFixed(1)} %</td>
                                                                </tr>
                                                            })
                                                        }
                                                        <tr>
                                                            <td><strong></strong></td>
                                                            <td><strong>VŠECHNY DŮVODY STOPU</strong></td>
                                                            <td><strong>{stopReasonsCount}</strong></td>
                                                            <td><strong>{Math.floor(stopReasonsMinutes / 60)} hodin {stopReasonsMinutes % 60} minut</strong></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Card style={{  margin: "5px" }}>
                                    <Card.Header>
                                        <h5>EFEKTIVITA PRODUKCE</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-3 ">
                                                <Card style={{  }}>
                                                    {typeof seriesOee != 'undefined' && typeof optionsOee != 'undefined' ? <Chart options={optionsOee} series={seriesOee} type="radialBar" height="300" style={{  marginBottom: "10px" }} /> : null}
                                                    <Card.Body style={{  borderTop: "1px solid silver" }} >
                                                        <h6></h6>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="col-3 ">
                                                <Card style={{  }}>
                                                    {typeof optionsAvailability != 'undefined' && typeof seriesAvailability != 'undefined' ? <Chart options={optionsAvailability} series={seriesAvailability} type="radialBar" height="300" style={{  marginBottom: "10px" }} /> : null }
                                                    <Card.Body style={{  borderTop: "1px solid silver" }} >
                                                        <h6 style={{  textAlign: "center", fontWeight: "bold" }} >Dostupný čas:</h6>
                                                        <h6 style={{  textAlign: "center" }} >{availableTimeHours} hodin {availableTimeMinutes} minut</h6>
                                                        <h6 style={{  textAlign: "center", fontSize: "8px" }} >Čas, po který je stroj dostupný</h6>
                                                    </Card.Body>
                                                    <Card.Body style={{  borderTop: "1px solid silver" }} >
                                                        <h6 style={{  textAlign: "center", fontWeight: "bold" }} >Čas produkce:</h6>
                                                        <h6 style={{  textAlign: "center" }} >{runMinutesHours} hodin {runMinutesMinutes} minut</h6>
                                                        <h6 style={{  textAlign: "center", fontSize: "8px" }} >Čas, po který stroj běží</h6>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="col-3 ">
                                                <Card style={{  }}>
                                                    {typeof optionsPerformance != 'undefined' && typeof seriesPerformance != 'undefined' ? <Chart options={optionsPerformance} series={seriesPerformance} type="radialBar" height="300" style={{  marginBottom: "10px" }} /> : null }
                                                    <Card.Body style={{  borderTop: "1px solid silver" }} >
                                                        <h6 style={{  textAlign: "center", fontWeight: "bold" }} >Očekávaný počet cyklů:</h6>
                                                        <h6 style={{  textAlign: "center" }} >{expectedCycles} cyclů</h6>
                                                        <h6 style={{  textAlign: "center", fontSize: "8px" }} >{expectedCyclesFormula}</h6>
                                                    </Card.Body>
                                                    <Card.Body style={{  borderTop: "1px solid silver" }} >
                                                        <h6 style={{  textAlign: "center", fontWeight: "bold" }} >Aktulální počet cyklů:</h6>
                                                        <h6 style={{  textAlign: "center" }} >{actualCycles} cyclů</h6>
                                                        <h6 style={{  textAlign: "center", fontSize: "8px" }} >Aktuální počet cyklů</h6>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="col-3 ">
                                                <Card style={{  }}>
                                                    {typeof optionsQuality != 'undefined' && typeof seriesQuality != 'undefined' ? <Chart options={optionsQuality} series={seriesQuality} type="radialBar" height="300" style={{  marginBottom: "10px" }} /> : null }
                                                    <Card.Body style={{  borderTop: "1px solid silver" }} >
                                                        <h6 style={{  textAlign: "center" }} >Použitý Materiál:</h6>
                                                        <h6 style={{  textAlign: "center" }} >{Math.round(materialConsumption)} kg</h6>
                                                        <h6 style={{  textAlign: "center", fontSize: "8px" }} >{materialConsumptionFormula}</h6>
                                                    </Card.Body>
                                                    <Card.Body style={{  borderTop: "1px solid silver" }} >
                                                        <h6 style={{  textAlign: "center" }} >Váha Produktů:</h6>
                                                        <h6 style={{  textAlign: "center" }} >{Math.round(productsWeight)} kg</h6>
                                                        <h6 style={{  textAlign: "center", fontSize: "8px" }} >{productsWeightFormula}</h6>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                                            <Form.Control className="hidden" type="range" value={this.state.oeePercent} onChange={this.oeePercentChange.bind(this)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Card style={{  margin: "5px" }}>
                                    <Card.Header>
                                        <h5>PRŮBĚH VÝROBY</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        {typeof options != 'undefined' && typeof series != 'undefined' ? <Chart options={options} series={series} height="350"/> : null }
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => this.props.jobReportPrintButtonPress(jobId)} hidden >PRINT</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
