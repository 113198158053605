import React from 'react';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalInOperation extends React.Component {
    constructor() {
        super();
        this.state = {
        }
        
    }

    componentDidMount() {}

    componentWillUnmount() {}

    modalTaggle() {
        console.log("ModalInOperation.js - modalTaggle ");
        this.props.modalTaggle();
    }


    render() {
        //var lang = cookies.get('lang');

        return (
            <div className={"text-blue-white"}>
                <Modal show={this.props.showModal} onHide={this.modalTaggle.bind(this)} dialogClassName="in-operation-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>IN OPERATION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>
                        <Spinner animation="border" />                    
                        <span style={{margin: "40px"}} >{this.props.textOfOperation}</span>
                        </h5>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
