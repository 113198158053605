import React from 'react';
import DataStore from "../../stores/DataStore";


import ImgAdd from '../images/IconAdd.png';
import ImgEdit from '../images/IconEdit.png';
import ImgSave from '../images/IconSave.png';
import ImgCancel from '../images/IconCancel.png';
import ImgDelete from '../images/IconDelete.png';
// BOOTSTRAP
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import HLSPlayer from 'react-hls-player';
import ReactHlsPlayer from "react-hls-player";

const pageName = ' Cameras.js';

export default class Cameras extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedCamera: '99',

        };
        //const playerRef = React.useRef();
    }

    componentDidMount() {}

    componentWillUnmount() {

    }

    changeCamera(cameraId) {
        this.setState({ selectedCamera: cameraId });
        //ReactHlsPlayer.current.play();
    }

    render() {
        //const playerRef = React.useRef();

        const radios = [
            { name: 'NÁHLED VŠE', value: '99' },
            { name: 'Popinecka 1', value: '31' },
            { name: 'Popinecka 2', value: '32' },
            { name: 'Popinecka 3', value: '33' },
            { name: 'Buničitá 1', value: '51' },
        ];
        var source;
        if (this.state.selectedCamera == "31") source = 'https://cameras.automation-server.automation.mobal.cz/stream/camera31/channel/0/hls/live/index.m3u8';
        if (this.state.selectedCamera == "32") source = 'https://cameras.automation-server.automation.mobal.cz/stream/camera32/channel/0/hls/live/index.m3u8';
        if (this.state.selectedCamera == "33") source = 'https://cameras.automation-server.automation.mobal.cz/stream/camera33/channel/0/hls/live/index.m3u8';
        if (this.state.selectedCamera == "51") source = 'https://cameras.automation-server.automation.mobal.cz/stream/camera51/channel/0/hls/live/index.m3u8';
        //const source = 'https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8';

        var player = <ReactHlsPlayer
            src={source}
            autoPlay={true}
            controls={false}
            width="80%"
            height="auto"
        />
        if (this.state.selectedCamera == "99") {
            var player1 = <ReactHlsPlayer
                src='https://cameras.automation-server.automation.mobal.cz/stream/camera31/channel/0/hls/live/index.m3u8'
                autoPlay={true}
                controls={false}
                muted="muted"
                width="80%"
                height="auto"
            />
            var player2 = <ReactHlsPlayer
                src='https://cameras.automation-server.automation.mobal.cz/stream/camera32/channel/0/hls/live/index.m3u8'
                autoPlay={true}
                controls={false}
                muted="muted"
                width="80%"
                height="auto"
            />
            var player3 = <ReactHlsPlayer
                src='https://cameras.automation-server.automation.mobal.cz/stream/camera33/channel/0/hls/live/index.m3u8'
                autoPlay={true}
                controls={false}
                muted="muted"
                width="80%"
                height="auto"
            />
            var player4 = <ReactHlsPlayer
                src='https://cameras.automation-server.automation.mobal.cz/stream/camera51/channel/0/hls/live/index.m3u8'
                autoPlay={true}
                controls={false}
                muted="muted"
                width="80%"
                height="auto"
            />
            player = <div><div className={"row"}><div className={"col-6"}>{player1}</div><div className={"col-6"}>{player2}</div></div><div className={"row"}><div className={"col-6"}>{player3}</div><div className={"col-6"}>{player4}</div></div></div>
        }


        return (
            <div className={"text-blue-white"}>
                <div className={"row"}>
                    <Card className="bg-dark text-white" style={{ width: "100%" }}>
                        <Card.Header>
                        
                        
                              <ToggleButtonGroup type="radio" name="radio" defaultValue={"99"}>
                                {radios.map((radio, idx) => (
                                  <ToggleButton
                                  size="lg"
                                    key={idx}
                                    id={`radio-${idx}`}
                                    variant='outline-primary'   
                                    value={radio.value}
                                    checked={this.state.selectedCamera === radio.value}
                                    onChange={(e) => this.changeCamera(e.currentTarget.value)}
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ToggleButtonGroup>
                        </Card.Header>
                        <Card.Body>
                            {player}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}
