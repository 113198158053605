import React from 'react';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button';
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            pinCode: '',
            starCode: '',

        }
    }

    componentDidMount() {}

    componentWillUnmount() {}

    pass1Click(e) {
        var code = this.state.pinCode;
        var starCode = this.state.starCode;
        if (code.length < 4) {
            var num = e.target.getAttribute('num');
            code = code + num;
            starCode = starCode + '*';
            this.setState({ pinCode: code, starCode: starCode });
        }
        if (code.length == 4) {
            this.props.checkPinCode(code);
        }
    }
    modalTaggle() {
        this.props.modalTaggle();
    }

    show() {
        this.setState({ pinCode: "", starCode: "" });
    }

    render() {
        //var lang = cookies.get('lang');

        var filter1 = 'blur(10px) opacity(0.5)';
        return (
            <div className={"text-blue-white"}>
                <Modal show={this.props.showModal} onShow={this.show.bind(this)} onHide={this.modalTaggle.bind(this)}  dialogClassName="" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title className={"password"} >ENTER YOUR PIN CODE: {this.state.starCode}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                <div className={"row"}>
                    <div className={"col-4"}>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={1} variant="primary" onClick={this.pass1Click.bind(this)}>1</Button>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={4} variant="primary" onClick={this.pass1Click.bind(this)}>4</Button>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={7} variant="primary" onClick={this.pass1Click.bind(this)}>7</Button>
                    </div>
                    <div className={"col-4"}>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={2} variant="primary" onClick={this.pass1Click.bind(this)}>2</Button>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={5} variant="primary" onClick={this.pass1Click.bind(this)}>5</Button>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={8} variant="primary" onClick={this.pass1Click.bind(this)}>8</Button>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={0} variant="primary" onClick={this.pass1Click.bind(this)}>0</Button> 
                    </div>

                    <div className={"col-4"}>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={3} variant="primary" onClick={this.pass1Click.bind(this)}>3</Button>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={6} variant="primary" onClick={this.pass1Click.bind(this)}>6</Button>
                        <Button style={{ width: '100px', height: "100px", margin: "5px", }} num={9} variant="primary" onClick={this.pass1Click.bind(this)}>9</Button>
                    </div>

                </div>



                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
