import React from 'react';
import DataStore from "../../stores/DataStore";
import ModalPassword from './ModalPassword';
import ModalDeleteConfirm from './ModalDeleteConfirm';
import ModalCreateRecipe from './ModalCreateRecipe';
import ModalWrongOperation from './ModalWrongOperation';
import ImgAdd from '../images/IconAdd.png';
import ImgEdit from '../images/IconEdit.png';
import ImgSave from '../images/IconSave.png';
import ImgCancel from '../images/IconCancel.png';
import ImgDelete from '../images/IconDelete.png';
// BOOTSTRAP
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
const pageName = ' Recipes.js';

export default class Recipes extends React.Component {
    constructor() {
        super();
        this.handleEditButton = this.handleEditButton.bind(this);
        this.state = {
            loggedUser: {},
            recipesData: [],
            toolsData: [],
            customersData: [],
            materialsData: [],
            editedId: 0,
            editedPrevName: '',
            editedPrevPartsCount: 0,
            showModalDelete: false,
            showModalCreate: false,
            showModalWrongOperation: false,
            textOfWrongOperation: '',
            styleFormPartsPerKilogram: '',
            styleFormPartsPerShift: '',
            styleFormPartsPerPackage: '',


            toolSearchFilter: '',
            materialSearchFilter: '',
            toolsDataFiltered: [],
            materialsDataFiltered: [],

        };

        setInterval(() => {
            // LOGOUT
            if (this.state.logoutCountdownCounter > 0) {
                this.setState({ logoutCountdownCounter: (this.state.logoutCountdownCounter - 1) });
                if (this.state.logoutCountdownCounter == 0) {
                    this.setState({ loggedUser: {} });
                }
            }
        }, 1000);
        
    }

    componentDidMount() {
        DataStore.on("clickLogin", this.clickLogin.bind(this));
        DataStore.on("checkRfidCodeComplete", this.checkRfidCodeComplete.bind(this));
        DataStore.on("checkPinCodeComplete", this.checkPinCodeComplete.bind(this));
        DataStore.on("logout", this.logout.bind(this));
        DataStore.on("getRecipesComplete", this.getRecipesComplete.bind(this));
        DataStore.on("mysqlError", this.mysqlError.bind(this));
        setTimeout(() => {
            DataStore.getRecipes(this.props.terminal);
        }, 1000);

    }

    componentWillUnmount() {
        DataStore.removeListener("clickLogin", this.clickLogin);
        DataStore.removeListener("checkRfidCodeComplete", this.checkRfidCodeComplete);
        DataStore.removeListener("checkPinCodeComplete", this.checkPinCodeComplete);
        DataStore.removeListener("logout", this.logout);
        DataStore.removeListener("getRecipesComplete", this.getRecipesComplete);
        DataStore.removeListener("mysqlError", this.mysqlError);

    }

        // USER PERMISSION
    checkRfidCodeComplete(terminal, user) {
        if (this.props.terminal == terminal) {
            console.log(pageName + " - checkRfidCodeComplete terminal: " + this.props.terminal + " name: " + user.user_name);
            console.log(terminal);
            this.setState({ loggedUser: user });
            this.setState({ logoutCountdownCounter: 300 });
            this.setState({ showLogoutCountdown: true });
        }
    }

    logout(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - logout ");
            this.setState({ loggedUser: {} });
        }
    }

    clickLogin(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - login ");
            //this.setState({ loggedUser: {} });
            this.setState({ showModalPassword: true });
        }
    }

    checkPinCode(code) {
        DataStore.checkPinCode(this.props.terminal, code);
    }

    checkPinCodeComplete(terminal, user) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - checkPinCodeComplete ");
            if (user.name != "Unknown pin code") {
                this.setState({ loggedUser: user });
                this.setState({ logoutCountdownCounter: 300 });
                if (terminal != 'terminalProduction' && terminal != 'terminalEntrance') this.setState({ logoutCountdownCounter: 1800 });
                this.setState({ showLogoutCountdown: true });
                this.setState({ showModalPassword: false });
            }
        }
    }

    pinCodeModalTaggle() {
        console.log(pageName + " - pinCodeModalTaggle ");
        this.setState({ showModalPassword: !this.state.showModalPassword });
    }


    getRecipesComplete(terminal, resultRecipes, resultCustomers, resultMaterials, resultTools) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - getRecipesComplete ");
            console.log(resultRecipes);
            console.log(resultCustomers);
            console.log(resultMaterials);
            console.log(resultTools);
            this.setState({ recipesData: resultRecipes, customersData: resultCustomers, materialsData: resultMaterials, toolsData: resultTools });
            this.setState({ customersDataFiltered: resultCustomers, materialsDataFiltered: resultMaterials, toolsDataFiltered: resultTools });
        }
    }

    mysqlError(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - mysqlError ");
            console.log(result);
            this.setState({ showModalWrongOperation: true, textOfWrongOperation: JSON.stringify(result) });
        }
    }


    handleEditButton(event, editedId, editedName, editedPartsCount) {
        console.log('handleEditButton');
        this.setState({ editedId: editedId });
        this.setState({ editedPrevName: editedName });
        this.setState({ editedPrevPartsCount: editedPartsCount });
        console.log(editedId);

    }

    handleSaveButton(event) {
        console.log('handleSaveButton');
        var t = this;
        var name = "";
        var recipe_tool_id = 0;
        var recipe_material_id = 0;
        var recipe_parts_per_kilogram = 0;
        var recipe_parts_per_shift = 0;
        var recipe_parts_per_package = 0;
        var recipe_cardboard = '';
        var recipe_divider = '';
        this.state.recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == t.state.editedId) {
                name = recipe.recipe_name;
                recipe_tool_id = recipe.recipe_tool_id;
                recipe_material_id = recipe.recipe_material_id;
                recipe_parts_per_kilogram = recipe.recipe_parts_per_kilogram;
                recipe_parts_per_shift = recipe.recipe_parts_per_shift;
                recipe_parts_per_package = recipe.recipe_parts_per_package;
                recipe_cardboard = recipe.recipe_cardboard;
                recipe_divider = recipe.recipe_divider;
            }
        })
        this.setState({ editedId: 0 });
        DataStore.updateRecipe(this.props.terminal, this.state.loggedUser.user_id, this.state.editedId, name, recipe_tool_id, recipe_material_id, recipe_parts_per_kilogram, recipe_parts_per_shift, recipe_parts_per_package, recipe_cardboard, recipe_divider);
    }

    handleCancelButton(event) {
        console.log('handleCancelButton');
        var t = this;

        var recipesData = this.state.recipesData;
        recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == t.state.editedId) {
                recipe.recipe_name = t.state.editedPrevName;
                recipe.recipe_parts_count = t.state.editedPrevPartsCount;
            }
        })
        this.setState({ editedId: 0 });
        this.setState({ recipesData: recipesData });
        //console.log(event);
    }

    handleDeleteButton(event, deletingId) {
        console.log('handleDeleteButton');
        this.setState({ showModalDelete: true });
        this.setState({ deletingId: deletingId });
        console.log(event);
    }

    handleCreateButton(event) {
        console.log('handleCreateButton');
        this.setState({ showModalCreate: true });
        console.log(event);
    }

    handleChangeName(event) {
        console.log('handleChangeName');
        var t = this;
        var recipesData = this.state.recipesData;
        recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == t.state.editedId) {
                recipe.recipe_name = event.target.value;
            }
        })
        this.setState({ recipesData: recipesData });
        console.log(event.target.value);
    }

    handleChangeTool(id, event) {
        console.log('handleChangeTool');
        var t = this;
        var recipesData = this.state.recipesData;
        recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == t.state.editedId) {
                recipe.recipe_tool_id = id;
            }
        })
        this.setState({ recipesData });
    }

    handleChangeMaterial(id) {
        console.log('handleChangeMaterial');
        var t = this;
        var recipesData = this.state.recipesData;
        recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == t.state.editedId) {
                recipe.recipe_material_id = id;
            }
        })
        this.setState({ recipesData });
        //console.log(id);
    }

    handleChangePartsPerKilogram(event) {
        console.log('handleChangePartsPerKilogram');
        var t = this;
        var value = Number(event.target.value);
        var recipesData = this.state.recipesData;
        if (!isNaN(value)) {
            recipesData.forEach(function(recipe) {
                if (recipe.recipe_id == t.state.editedId) {
                    recipe.recipe_parts_per_kilogram = event.target.value;
                }
            })
            this.setState({ recipesData: recipesData });
        }
        else {
            this.setState({ styleFormPartsPerKilogram: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormPartsPerKilogram: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangePartsPerShift(event) {
        console.log('handleChangePartsPerShift');
        var t = this;
        var value = Number(event.target.value);
        var recipesData = this.state.recipesData;
        if (!isNaN(value)) {
            recipesData.forEach(function(recipe) {
                if (recipe.recipe_id == t.state.editedId) {
                    recipe.recipe_parts_per_shift = event.target.value;
                }
            })
            this.setState({ recipesData: recipesData });
        }
        else {
            this.setState({ styleFormPartsPerShift: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormPartsPerShift: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangePartsPerPackage(event) {
        console.log('handleChangePartsPerPackage');
        var t = this;
        var value = Number(event.target.value);
        var recipesData = this.state.recipesData;
        if (!isNaN(value)) {
            recipesData.forEach(function(recipe) {
                if (recipe.recipe_id == t.state.editedId) {
                    recipe.recipe_parts_per_package = event.target.value;
                }
            })
            this.setState({ recipesData: recipesData });
        }
        else {
            this.setState({ styleFormPartsPerPackage: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormPartsPerPackage: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangeCardboard(event) {
        console.log('handleChangeCardboard');
        var t = this;
        var recipesData = this.state.recipesData;
        recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == t.state.editedId) {
                recipe.recipe_cardboard = event.target.value;
            }
        })
        this.setState({ recipesData: recipesData });
        console.log(event.target.value);
    }

    handleChangeDivider(event) {
        console.log('handleChangeDivider');
        var t = this;
        var recipesData = this.state.recipesData;
        recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == t.state.editedId) {
                recipe.recipe_divider = event.target.value;
            }
        })
        this.setState({ recipesData: recipesData });
        console.log(event.target.value);
    }





    modalDeleteConfirmClose(event) {
        console.log('modalDeleteConfirmClose');
        this.setState({ showModalDelete: false });
    }

    modalDeleteConfirmCancel(event) {
        console.log('modalDeleteConfirmCancel');
        this.setState({ showModalDelete: false });
    }

    modalDeleteConfirmDelete(event) {
        console.log('modalDeleteConfirmDelete');
        this.setState({ showModalDelete: false });
        this.setState({ editedId: 0 });
        DataStore.deleteRecipe(this.props.terminal, this.state.loggedUser.user_id, this.state.deletingId);
        console.log(this.state.deletingId);
    }


    modalCreateClose(event) {
        console.log('modalCreateClose');
        this.setState({ showModalCreate: false });
    }

    modalCreateCancel(event) {
        console.log('modalCreateCancel');
        this.setState({ showModalCreate: false });
    }

    modalCreateAdd(name, recipe_tool_id, recipe_material_id, recipe_parts_per_kilogram, recipe_parts_per_shift, recipe_parts_per_package, recipe_cardboard, recipe_divider) {
        console.log('modalCreateAdd');
        this.setState({ showModalCreate: false });
        DataStore.createRecipe(this.props.terminal, this.state.loggedUser.user_id, name, recipe_tool_id, recipe_material_id, recipe_parts_per_kilogram, recipe_parts_per_shift, recipe_parts_per_package, recipe_cardboard, recipe_divider);
        //console.log(name + ' | ' + recipe_tool_id);
    }


    modalWrongOperationClose(event) {
        console.log('modalWrongOperationClose');
        this.setState({ showModalWrongOperation: false });
    }




    refreshToolFilter(event) {
        var value = event.target.value;
        var toolsDataFiltered = [];
        this.setState({ toolSearchFilter: value });
        this.state.toolsData.forEach(function(tool) {
            if (String(tool.tool_name).toUpperCase().search(value.toUpperCase()) >= 0) {
                toolsDataFiltered.push(tool);
            }
        })
        this.setState({ toolsDataFiltered });
    }


    refreshMateriallFilter(event) {
        var value = event.target.value;
        var materialsDataFiltered = [];
        this.setState({ materialSearchFilter: value });
        this.state.materialsData.forEach(function(material) {
            if (String(material.material_name).toUpperCase().search(value.toUpperCase()) >= 0 || String(material.material_type).toUpperCase().search(value.toUpperCase()) >= 0 || String(material.material_width).toUpperCase().search(value.toUpperCase()) >= 0 || String(material.material_thickness).toUpperCase().search(value.toUpperCase()) >= 0) {
                materialsDataFiltered.push(material);
            }
        })
        this.setState({ materialsDataFiltered });
    }

    getCustomerName(id) {
        var name = "NEVYBRÁNO";
        this.state.customersData.forEach(function(customer) {
            if (customer.customer_id == id) {
                name = customer.customer_name;
            }
        })
        return name;
    }

    getToolName(id) {
        var name = "NEVYBRÁNO";
        this.state.toolsData.forEach(function(tool) {
            if (tool.tool_id == id) {
                name = tool.tool_name;
            }
        })
        return name;
    }

    getMaterialName(id) {
        var name = "NEVYBRÁNO";
        this.state.materialsData.forEach(function(material) {
            if (material.material_id == id) {
                name = material.material_type + ' ' + material.material_width + 'mm x ' + material.material_thickness + 'µm';
            }
        })
        return name;
    }


    getMaterialDropdown() {
        this.state.toolsDataFiltered.map((item, key) => {
            return (
                <Dropdown.Item as="button" key={key}>{item.tool_name}</Dropdown.Item>
            )
        })
    }



    render() {
        var userCanEdit = DataStore.checkMinimumAuthorization(this.state.loggedUser, 'planner');
        
        return (
            <div className={"text-blue-white"}>
                <div className={"row"}>
                    <Card.Body>
                        <Table className="text-white">
                            <thead>
                                <tr>
                                    <th width="5%">ID</th>
                                    <th width="10%">NÁZEV</th>
                                    <th width="10%">FORMA</th>
                                    <th width="10%">MATERIÁL</th>
                                    <th width="10%">KS/KG</th>
                                    <th width="5%">KS/SMĚNU</th>
                                    <th width="5%">KS/BALENÍ</th>
                                    <th width="5%">KARTON</th>
                                    <th width="5%">ROZDĚLOVNÍK</th>
                                    <th width="25%">AKCE { userCanEdit ? <Button style={{float: "right", marginRight: "10px" }} variant="outline-success" onClick={this.handleCreateButton.bind(this)} >VYTVOŘIT<img style={{ marginLeft: "10px"}} src={ImgAdd} alt="ImgAdd" width="20px" /></Button> : "" }</th>
                                    </tr>
                            </thead>
                             <tbody>
                                {
                                    this.state.recipesData.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{item.recipe_id}</td>
                                                {this.state.editedId==item.recipe_id
                                                    ? <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={item.recipe_name} onChange={(event) => this.handleChangeName(event)} /></td>
                                                    : <td>{item.recipe_name}</td>
                                                }
                                                {this.state.editedId==item.recipe_id
                                                    ? 
                                                    <td>
                                                        <DropdownButton variant="light" title={this.getToolName(item.recipe_tool_id)} >
                                                            <Form.Control className={"dropdown-menu-search"} value={this.state.toolSearchFilter} onChange={(event) => this.refreshToolFilter(event)} />
                                                                {
                                                                this.state.toolsDataFiltered.map((tool, tool_key) => {
                                                                    return (
                                                                        <Dropdown.Item as="button" eventKey={tool.tool_id} key={tool.tool_id} onSelect={this.handleChangeTool.bind(this)}>{tool.tool_name}</Dropdown.Item>
                                                                    )
                                                                })}
                                                        </DropdownButton>
                                                    </td>
                                                    : <td>{this.getToolName(item.recipe_tool_id)}</td>
                                                }
                                                {this.state.editedId==item.recipe_id
                                                    ? 
                                                    <td>
                                                        <DropdownButton variant="light" title={this.getMaterialName(item.recipe_material_id)}>
                                                            <Form.Control className={"dropdown-menu-search"} value={this.state.materialSearchFilter} onChange={(event) => this.refreshMateriallFilter(event)} />
                                                            {
                                                            this.state.materialsDataFiltered.map((material, key) => {
                                                                return (
                                                                    <Dropdown.Item as="button" eventKey={material.material_id} key={material.material_id} onSelect={this.handleChangeMaterial.bind(this)}>{material.material_type} {material.material_width}mm x {material.material_thickness}µm</Dropdown.Item>
                                                                )
                                                            })}
                                                        </DropdownButton>
                                                    </td>
                                                    : <td>{this.getMaterialName(item.recipe_material_id)}</td>
                                                }
                                                {this.state.editedId==item.recipe_id
                                                    ? <td><Form.Control className={this.state.styleFormPartsPerKilogram} style={{ width: '100%', textAlign: 'center' }} value={item.recipe_parts_per_kilogram} onChange={(event) => this.handleChangePartsPerKilogram(event)} /></td>
                                                    : <td>{item.recipe_parts_per_kilogram}</td>
                                                }
                                                {this.state.editedId==item.recipe_id
                                                    ? <td><Form.Control className={this.state.styleFormPartsPerShift} style={{ width: '100%', textAlign: 'center' }} value={item.recipe_parts_per_shift} onChange={(event) => this.handleChangePartsPerShift(event)} /></td>
                                                    : <td>{item.recipe_parts_per_shift}</td>
                                                }
                                                {this.state.editedId==item.recipe_id
                                                    ? <td><Form.Control className={this.state.styleFormPartsPerPackage} style={{ width: '100%', textAlign: 'center' }} value={item.recipe_parts_per_package} onChange={(event) => this.handleChangePartsPerPackage(event)} /></td>
                                                    : <td>{item.recipe_parts_per_package}</td>
                                                }
                                                {this.state.editedId==item.recipe_id
                                                    ? <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={item.recipe_cardboard} onChange={(event) => this.handleChangeCardboard(event)} /></td>
                                                    : <td>{item.recipe_cardboard}</td>
                                                }
                                                {this.state.editedId==item.recipe_id
                                                    ? <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={item.recipe_divider} onChange={(event) => this.handleChangeDivider(event)} /></td>
                                                    : <td>{item.recipe_divider}</td>
                                                }
                                                <td>
                                                    { userCanEdit ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-danger" onClick={(event) => this.handleDeleteButton(event, item.recipe_id)} >SMAZAT<img style={{ marginLeft: "10px"}} src={ImgDelete} alt="ImgDelete" width="20px" /></Button> : "" }
                                                    {this.state.editedId==0 && userCanEdit
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-warning" onClick={(event) => this.handleEditButton(event, item.recipe_id, item.recipe_name, item.recipe_parts_count)}>EDITOVAT<img style={{ marginLeft: "10px"}} src={ImgEdit} alt="ImgEdit" width="20px" /></Button>
                                                        : null
                                                    }
                                                    {this.state.editedId==item.recipe_id && userCanEdit
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-warning" onClick={this.handleCancelButton.bind(this)} >ZRUŠIT<img style={{ marginLeft: "10px"}} src={ImgCancel} alt="ImgCancel" width="20px" /></Button>
                                                        : null
                                                    }
                                                    {this.state.editedId==item.recipe_id && userCanEdit
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-success" onClick={this.handleSaveButton.bind(this)} >ULOŽIT<img style={{ marginLeft: "10px"}} src={ImgSave} alt="ImgSave" width="20px" /></Button>
                                                        : null
                                                    }
                                                    
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </div>
                <ModalPassword showModal={this.state.showModalPassword} checkPinCode={this.checkPinCode.bind(this) } modalTaggle={this.pinCodeModalTaggle.bind(this)} />
                <ModalDeleteConfirm showModal={this.state.showModalDelete} modalClose={this.modalDeleteConfirmClose.bind(this)} modalCancel={this.modalDeleteConfirmCancel.bind(this)} modalDelete={this.modalDeleteConfirmDelete.bind(this)} />
                <ModalCreateRecipe showModal={this.state.showModalCreate} modalClose={this.modalCreateClose.bind(this)} modalCancel={this.modalCreateCancel.bind(this)} modalAdd={this.modalCreateAdd.bind(this)} toolsData={this.state.toolsData} materialsData={this.state.materialsData} />
                <ModalWrongOperation showModal={this.state.showModalWrongOperation} textOfOperation={this.state.textOfWrongOperation} modalClose={this.modalWrongOperationClose.bind(this)}/>
            </div>
        );
    }
}
