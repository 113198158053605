import { EventEmitter } from "events";
import axios from 'axios';
import dispatcher from "../dispatcher";
import openSocket from 'socket.io-client';
var FormData = require('form-data');

const socket = openSocket('https://backend.automation-server.automation.mobal.cz');
const apiAddress = 'https://backend2.automation-server.automation.mobal.cz/'
class DataStore extends EventEmitter {
    constructor() {
        super()

        // SOCKET EVENTS
        socket.on('mysqlError', this.mysqlError.bind(this));
        socket.on('getRecipesComplete', this.getRecipesComplete.bind(this));
        socket.on('getToolsComplete', this.getToolsComplete.bind(this));
        socket.on('getMaterialsComplete', this.getMaterialsComplete.bind(this));
        socket.on('getCustomersComplete', this.getCustomersComplete.bind(this));
        socket.on('getJobsComplete', this.getJobsComplete.bind(this));
        socket.on('getJobsHistoryComplete', this.getJobsHistoryComplete.bind(this));
        socket.on('getDLPanelDataComplete', this.getDLPanelDataComplete.bind(this));
        socket.on('checkPinCodeComplete', this.checkPinCodeComplete.bind(this));
        socket.on('checkRfidCodeComplete', this.checkRfidCodeComplete.bind(this));
        socket.on('getDataloggerDashboardComplete', this.getDataloggerDashboardComplete.bind(this));
        socket.on('getJobReportDataComplete', this.getJobReportDataComplete.bind(this));
        socket.on('getJobImagesComplete', this.getJobImagesComplete.bind(this));
        socket.on('getEventLogsComplete', this.getEventLogsComplete.bind(this));
        socket.on('getCameraImageComplete', this.getCameraImageComplete.bind(this));
        socket.on('machineStopped', this.machineStopped.bind(this));

    }

    mysqlError(terminal, result) {
        console.log("DataStore.js - mysqlError terminal: " + terminal);
        this.emit("mysqlError", terminal, result);
    }

    checkMinimumAuthorization(user, role) {
        var authorized = false;
        if (role == 'shiftleader') {
            if (user.user_role == "administrator") {
                authorized = true;
            }
            if (user.user_role == "planner") {
                authorized = true;
            }
            if (user.user_role == "shiftleader") {
                authorized = true;
            }
        }
        if (role == 'planner') {
            if (user.user_role == "administrator") {
                authorized = true;
            }
            if (user.user_role == "planner") {
                authorized = true;
            }
        }
        if (role == 'administrator') {
            if (user.user_role == "administrator") {
                authorized = true;
            }
        }
        return authorized;
    }

    clickLogin(terminal) {
        this.emit("clickLogin", terminal);
    }


    checkPinCode(terminal, code) {
        console.log("DataStore.js - getTask");
        try {
            const dt = { code };
            axios.post(apiAddress + terminal + '/checkPinCode/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    checkPinCodeComplete(terminal, user) {
        this.emit("checkPinCodeComplete", terminal, user);
    }

    checkRfidCodeComplete(terminal, user) {
        this.emit("checkRfidCodeComplete", terminal, user);
    }

    logout(terminal) {
        this.emit("logout", terminal);
    }






    ////////////////////////
    // TOOLS
    ////////////////////////
    getTools(terminal) {
        console.log("DataStore.js - getTools");
        try {
            axios.get(apiAddress + terminal + '/getTools');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getToolsComplete(terminal, result) {
        console.log("DataStore.js - getToolsComplete terminal: " + terminal);
        this.emit("getToolsComplete", terminal, result);
    }

    deleteTool(terminal, userId, id) {
        console.log("DataStore.js - deleteTool");
        const dt = { userId, toolId: id };
        try {
            axios.delete(apiAddress + terminal + '/deleteTool/' + id, dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    createTool(terminal, userId, name, partsCount) {
        console.log("DataStore.js - createTool");
        const dt = { userId, name, partsCount };
        try {
            axios.post(apiAddress + terminal + '/createTool/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    updateTool(terminal, userId, id, name, partsCount) {
        console.log("DataStore.js - updateTool");
        const dt = { userId, name, partsCount };
        try {
            axios.patch(apiAddress + terminal + '/updateTool/' + id, dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }



    ////////////////////////
    // MATERIALS
    ////////////////////////
    getMaterials(terminal) {
        console.log("DataStore.js - getMaterials");
        try {
            axios.get(apiAddress + terminal + '/getMaterials');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getMaterialsComplete(terminal, result) {
        console.log("DataStore.js - getMaterialsComplete terminal: " + terminal);
        this.emit("getMaterialsComplete", terminal, result);
    }

    deleteMaterial(terminal, userId, id) {
        console.log("DataStore.js - deleteMaterial");
        const dt = { userId, materialid: id };
        try {
            axios.delete(apiAddress + terminal + '/deleteMaterial/' + id, dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    createMaterial(terminal, userId, name, type, thickness, width) {
        console.log("DataStore.js - createMaterial");
        const dt = { userId, name, type, thickness, width };
        try {
            axios.post(apiAddress + terminal + '/createMaterial/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    updateMaterial(terminal, userId, id, name, type, thickness, width) {
        console.log("DataStore.js - updateMaterial");
        const dt = { userId, name, type, thickness, width };
        try {
            axios.patch(apiAddress + terminal + '/updateMaterial/' + id, dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }


    ////////////////////////
    // CUSTOMERS
    ////////////////////////
    getCustomers(terminal) {
        console.log("DataStore.js - getCustomers");
        try {
            axios.get(apiAddress + terminal + '/getCustomers');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getCustomersComplete(terminal, result) {
        console.log("DataStore.js - getCustomersComplete terminal: " + terminal);
        this.emit("getCustomersComplete", terminal, result);
    }

    deleteCustomer(terminal, id) {
        console.log("DataStore.js - deleteCustomer");
        try {
            axios.delete(apiAddress + terminal + '/deleteCustomer/' + id);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    createCustomer(terminal, name) {
        console.log("DataStore.js - createCustomer");
        const dt = { name };
        try {
            axios.post(apiAddress + terminal + '/createCustomer/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    updateCustomer(terminal, id, name) {
        console.log("DataStore.js - updateCustomer");
        const dt = { name };
        try {
            axios.patch(apiAddress + terminal + '/updateCustomer/' + id, dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }




    ////////////////////////
    // RECIPES
    ////////////////////////
    getRecipes(terminal) {
        console.log("DataStore.js - getRecipes");
        try {
            axios.get(apiAddress + terminal + '/getRecipes');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getRecipesComplete(terminal, resultRecipes, resultCustomers, resultMaterials, resultTools) {
        console.log("DataStore.js - getRecipesComplete terminal: " + terminal);
        this.emit("getRecipesComplete", terminal, resultRecipes, resultCustomers, resultMaterials, resultTools);
    }

    deleteRecipe(terminal, userId, id) {
        console.log("DataStore.js - deleteRecipe");
        const dt = { userId, recippeId: id };
        try {
            axios.delete(apiAddress + terminal + '/deleteRecipe/' + id, dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    createRecipe(terminal, userId, recipe_name, recipe_tool_id, recipe_material_id, recipe_parts_per_kilogram, recipe_parts_per_shift, recipe_parts_per_package, recipe_cardboard, recipe_divider) {
        console.log("DataStore.js - createRecipe");
        const dt = { userId, recipe_name, recipe_tool_id, recipe_material_id, recipe_parts_per_kilogram, recipe_parts_per_shift, recipe_parts_per_package, recipe_cardboard, recipe_divider };
        try {
            axios.post(apiAddress + terminal + '/createRecipe/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    updateRecipe(terminal, userId, id, recipe_name, recipe_tool_id, recipe_material_id, recipe_parts_per_kilogram, recipe_parts_per_shift, recipe_parts_per_package, recipe_cardboard, recipe_divider) {
        console.log("DataStore.js - updateRecipe");
        const dt = { userId, recipe_name, recipe_tool_id, recipe_material_id, recipe_parts_per_kilogram, recipe_parts_per_shift, recipe_parts_per_package, recipe_cardboard, recipe_divider };
        try {
            axios.patch(apiAddress + terminal + '/updateRecipe/' + id, dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }


    ////////////////////////
    // JOBS
    ////////////////////////
    getJobs(terminal) {
        console.log("DataStore.js - getJobs");
        try {
            axios.get(apiAddress + terminal + '/getJobs');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getJobsComplete(terminal, result, resultRecipes) {
        console.log("DataStore.js - getJobsComplete terminal: " + terminal);
        this.emit("getJobsComplete", terminal, result, resultRecipes);
    }

    getJobsHistory(terminal) {
        console.log("DataStore.js - getJobsHistory");
        try {
            axios.get(apiAddress + terminal + '/getJobsHistory');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getJobsHistoryComplete(terminal, result, resultRecipes) {
        console.log("DataStore.js - getJobsHistoryComplete terminal: " + terminal);
        this.emit("getJobsHistoryComplete", terminal, result, resultRecipes);
    }

    createJob(terminal, userId, job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan, job_note, job_divider_note) {
        console.log("DataStore.js - createJob");
        const dt = { userId, job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan, job_note, job_divider_note };
        try {
            axios.post(apiAddress + terminal + '/createJob/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    updateJob(terminal, userId, job_id, job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan, job_note, job_divider_note) {
        console.log("DataStore.js - updateJob");
        const dt = { userId, job_id, job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan, job_note, job_divider_note };
        try {
            axios.post(apiAddress + terminal + '/updateJob/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    startJob(terminal, userId, jobId) {
        console.log("DataStore.js - startJob: " + jobId);
        const dt = { userId, jobId };
        try {
            axios.post(apiAddress + terminal + '/startJob/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    stopJob(terminal, userId, jobId) {
        console.log("DataStore.js - stopJob: " + jobId);
        const dt = { userId, jobId };
        try {
            axios.post(apiAddress + terminal + '/stopJob/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    finishJob(terminal, userId, jobId) {
        console.log("DataStore.js - finishJob: " + jobId);
        const dt = { userId, jobId };
        try {
            axios.post(apiAddress + terminal + '/finishJob/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getJobReportData(terminal, job) {
        console.log("DataStore.js - getJobReportData: " + job.job_id);
        const dt = { job };
        try {
            axios.post(apiAddress + terminal + '/getJobReportData/', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getJobReportDataComplete(terminal, data) {
        this.emit("getJobReportDataComplete", terminal, data);
    }



    ////////////////////////
    // EVENT LOGS
    ////////////////////////
    getEventLogs(terminal) {
        console.log("DataStore.js - getEventLogs");
        try {
            axios.get(apiAddress + terminal + '/getEventLogs');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getEventLogsComplete(terminal, data) {
        console.log("DataStore.js - getEventLogsComplete terminal: " + terminal);
        this.emit("getEventLogsComplete", terminal, data);
    }



    ////////////////////////
    // DL PANELS
    ////////////////////////
    getDLPanelData(terminal) {
        console.log("DataStore.js - getDLPanelData");
        const dt = {};
        try {
            axios.post(apiAddress + terminal + '/getDLPanelData', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    DLPanelStopReasonPressed(terminal) {
        console.log("DataStore.js - DLPanelStopReasonPressed");
        const dt = {};
        try {
            axios.post(apiAddress + terminal + '/DLPanelStopReasonPressed', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getDLPanelDataComplete(terminal, resultMachine, resultJob) {
        console.log("DataStore.js - getDLPanelDataComplete terminal: " + terminal);
        this.emit("getDLPanelDataComplete", terminal, resultMachine, resultJob);
    }

    machineStopped(terminal) {
        console.log("DataStore.js - machineStopped terminal: " + terminal);
        this.emit("machineStopped", terminal);
    }




    ////////////////////////
    // DASHBOARD
    ////////////////////////
    getDataloggerDashboard(terminal) {
        console.log("DataStore.js - getDataloggerDashboard");
        //const dt = { machineNumber };
        try {
            axios.get(apiAddress + terminal + '/getDataloggerDashboard');
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }


    getDataloggerDashboardComplete(terminal, resultJobs, resultMachines) {
        console.log("DataStore.js - getDataloggerDashboardComplete terminal: " + terminal);
        this.emit("getDataloggerDashboardComplete", terminal, resultJobs, resultMachines);
    }

    resetMaintenance(terminal, machineId, userId) {
        console.log("DataStore.js - resetMaintenance");
        const dt = { machineId, userId };
        try {
            axios.post(apiAddress + terminal + '/resetMaintenance', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    resetMaintenance2(terminal, machineId, userId) {
        console.log("DataStore.js - resetMaintenance2");
        const dt = { machineId, userId };
        try {
            axios.post(apiAddress + terminal + '/resetMaintenance2', dt);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }






    ////////////////////////
    // TERMINAL TEST STATION
    ////////////////////////
    // SAVE QUALITY CHECK PICTURE
    async saveTestStationPicture(terminal, job, image, userName) {
        //logger.log(ModuleName, 'addQualityAttachment ID: ' + data.taskId);
        const fs = require('fs')
        const form = new FormData();
        const meta = '{ "name": "camera.jpg" }';
        form.append('content-type', 'application/json');
        form.append('jobName', job.job_name);
        form.append('userName', userName);
        //form.append('file', fs.createReadStream('/home/multitray/c9sdk/automation-system-backend/temp/snapshot.jpg'));
        form.append('file', image);
        var body = {};
        var opt = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
            }
        }
        try {
            //let res = await axios.post(url, form, opt);
            axios.post(apiAddress + terminal + '/saveTestStationPicture', form, opt);
        }
        catch (error) {
            console.error("ERR: " + error)
            //logger.log(ModuleName, "addQualityAttachment ERR: " + error)
        }
    }

    getJobImages(terminal, jobName) {
        try {
            axios.get(apiAddress + terminal + '/getJobImages/' + jobName);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getJobImagesComplete(terminal, data) {
        console.log("DataStore.js - getJobImagesComplete terminal: " + terminal);
        this.emit("getJobImagesComplete", terminal, data);
    }


    getCameraImage(terminal, cameraId) {
        try {
            axios.get(apiAddress + terminal + '/getCameraImage/' + cameraId);
        }
        catch (error) {
            console.error("ERR: " + error)
        }
    }

    getCameraImageComplete(terminal, data) {
        console.log("DataStore.js - getCameraImageComplete terminal: " + terminal);
        this.emit("getCameraImageComplete", terminal, data);
    }






    handleActions(action) {
        switch (action.type) {
            case "PRODUCTION_SUBTASK_GET_ACTIVITIES":
                {
                    this.getTaskProductionActivities(action.task);
                    break;
                }
            default:
                break;
        }
    }

}



const dataStore = new DataStore();
dispatcher.register(dataStore.handleActions.bind(dataStore));

export default dataStore;
