import React from 'react';
import DataStore from "../../stores/DataStore";
import ModalPassword from './ModalPassword';
import ModalDeleteConfirm from './ModalDeleteConfirm';
import ModalCreateMaterial from './ModalCreateMaterial';
import ModalWrongOperation from './ModalWrongOperation';
import ImgAdd from '../images/IconAdd.png';
import ImgEdit from '../images/IconEdit.png';
import ImgSave from '../images/IconSave.png';
import ImgCancel from '../images/IconCancel.png';
import ImgDelete from '../images/IconDelete.png';
// BOOTSTRAP
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
const pageName = ' Recipes.js';

export default class Materials extends React.Component {
    constructor() {
        super();
        this.handleEditButton = this.handleEditButton.bind(this);
        this.state = {
            loggedUser: {},
            showModalDelete: false,
            showModalCreate: false,
            showModalWrongOperation: false,
            textOfWrongOperation: '',
            materialsData: [],
            styleFormPartsCount: '',
            editedId: 0,
            editedPrevName: '',
            editedPrevType: '',
            editedPrevThickness: 0,
            editedPrevWidth: 0,

        };

        setInterval(() => {
            // LOGOUT
            if (this.state.logoutCountdownCounter > 0) {
                this.setState({ logoutCountdownCounter: (this.state.logoutCountdownCounter - 1) });
                if (this.state.logoutCountdownCounter == 0) {
                    this.setState({ loggedUser: {} });
                }
            }
        }, 1000);
        
    }

    componentDidMount() {
        DataStore.on("clickLogin", this.clickLogin.bind(this));
        DataStore.on("checkRfidCodeComplete", this.checkRfidCodeComplete.bind(this));
        DataStore.on("checkPinCodeComplete", this.checkPinCodeComplete.bind(this));
        DataStore.on("logout", this.logout.bind(this));
        DataStore.on("getMaterialsComplete", this.getMaterialsComplete.bind(this));
        DataStore.on("mysqlError", this.mysqlError.bind(this));
        setTimeout(() => {
            DataStore.getMaterials(this.props.terminal);
        }, 1000);

    }

    componentWillUnmount() {
        DataStore.removeListener("clickLogin", this.clickLogin);
        DataStore.removeListener("checkRfidCodeComplete", this.checkRfidCodeComplete);
        DataStore.removeListener("checkPinCodeComplete", this.checkPinCodeComplete);
        DataStore.removeListener("logout", this.logout);
        DataStore.removeListener("getMaterialsComplete", this.getMaterialsComplete);
        DataStore.removeListener("mysqlError", this.mysqlError);

    }
    
        // USER PERMISSION
    checkRfidCodeComplete(terminal, user) {
        if (this.props.terminal == terminal) {
            console.log(pageName + " - checkRfidCodeComplete terminal: " + this.props.terminal + " name: " + user.user_name);
            console.log(terminal);
            this.setState({ loggedUser: user });
            this.setState({ logoutCountdownCounter: 300 });
            this.setState({ showLogoutCountdown: true });
        }
    }

    logout(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - logout ");
            this.setState({ loggedUser: {} });
        }
    }

    clickLogin(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - login ");
            //this.setState({ loggedUser: {} });
            this.setState({ showModalPassword: true });
        }
    }

    checkPinCode(code) {
        DataStore.checkPinCode(this.props.terminal, code);
    }

    checkPinCodeComplete(terminal, user) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - checkPinCodeComplete ");
            if (user.name != "Unknown pin code") {
                this.setState({ loggedUser: user });
                this.setState({ logoutCountdownCounter: 300 });
                if (terminal != 'terminalProduction' && terminal != 'terminalEntrance') this.setState({ logoutCountdownCounter: 1800 });
                this.setState({ showLogoutCountdown: true });
                this.setState({ showModalPassword: false });
            }
        }
    }

    pinCodeModalTaggle() {
        console.log(pageName + " - pinCodeModalTaggle ");
        this.setState({ showModalPassword: !this.state.showModalPassword });
    }




    getMaterialsComplete(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - getMaterialsComplete ");
            console.log(result);
            this.setState({ materialsData: result });
        }
    }

    mysqlError(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - mysqlError ");
            console.log(result);
            this.setState({ showModalWrongOperation: true, textOfWrongOperation: JSON.stringify(result) });
        }
    }


    handleEditButton(event, editedId, editedName, editedType, editedThickness, editedWidth) {
        console.log('handleEditButton');
        this.setState({ editedId: editedId });
        this.setState({ editedPrevName: editedName });
        this.setState({ editedPrevType: editedType });
        this.setState({ editedPrevThickness: editedThickness });
        this.setState({ editedPrevWidth: editedWidth });
        //console.log(editedId);

    }

    handleSaveButton(event) {
        console.log('handleSaveButton');
        var t = this;
        var name = "";
        var type = "";
        var thickness = 0;
        var width = 0;
        this.state.materialsData.forEach(function(material) {
            if (material.material_id == t.state.editedId) {
                name = material.material_name;
                type = material.material_type;
                thickness = material.material_thickness;
                width = material.material_width;
            }
        })
        this.setState({ editedId: 0 });
        DataStore.updateMaterial(this.props.terminal, this.state.loggedUser.user_id, this.state.editedId, name, type, thickness, width);
    }

    handleCancelButton(event) {
        console.log('handleCancelButton');
        var t = this;

        var materialsData = this.state.materialsData;
        materialsData.forEach(function(material) {
            if (material.material_id == t.state.editedId) {
                material.material_name = t.state.editedPrevName;
                material.material_type = t.state.editedPrevType;
                material.material_thickness = t.state.editedPrevThickness;
                material.material_width = t.state.editedPrevWidth;
            }
        })
        this.setState({ editedId: 0 });
        this.setState({ materialsData: materialsData });
        //console.log(event);
    }

    handleDeleteButton(event, deletingId) {
        console.log('handleDeleteButton');
        this.setState({ showModalDelete: true });
        this.setState({ deletingId: deletingId });
        //console.log(event);
    }

    handleCreateButton(event) {
        console.log('handleCreateButton');
        this.setState({ showModalCreate: true });
        //console.log(event);
    }

    handleChangeName(event) {
        console.log('handleChangeName');
        var t = this;
        var materialsData = this.state.materialsData;
        materialsData.forEach(function(material) {
            if (material.material_id == t.state.editedId) {
                material.material_name = event.target.value;
            }
        })
        this.setState({ materialsData: materialsData });
        //console.log(event.target.value);
    }

    handleChangeType(event) {
        console.log('handleChangeType');
        var t = this;
        var materialsData = this.state.materialsData;
        materialsData.forEach(function(material) {
            if (material.material_id == t.state.editedId) {
                material.material_type = event.target.value;
            }
        })
        this.setState({ materialsData: materialsData });
        //console.log(event.target.value);
    }

    handleChangeWidth(event) {
        console.log('handleChangeWidth');
        var t = this;
        var value = Number(event.target.value);
        var materialsData = this.state.materialsData;
        if (!isNaN(value)) {
            materialsData.forEach(function(material) {
                if (material.material_id == t.state.editedId) {
                    material.material_width = event.target.value;
                }
            })
            this.setState({ materialsData: materialsData });
        }
        else {
            this.setState({ styleFormWidth: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormWidth: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangeThickness(event) {
        console.log('handleChangeThickness');
        var t = this;
        var value = Number(event.target.value);
        var materialsData = this.state.materialsData;
        if (!isNaN(value)) {
            materialsData.forEach(function(material) {
                if (material.material_id == t.state.editedId) {
                    material.material_thickness = event.target.value;
                }
            })
            this.setState({ materialsData: materialsData });
        }
        else {
            this.setState({ styleFormThickness: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormThickness: "" });
            }, 500);
        }
        console.log(value);
    }

    modalDeleteConfirmClose(event) {
        console.log('modalDeleteConfirmClose');
        this.setState({ showModalDelete: false });
    }

    modalDeleteConfirmCancel(event) {
        console.log('modalDeleteConfirmCancel');
        this.setState({ showModalDelete: false });
    }

    modalDeleteConfirmDelete(event) {
        console.log('modalDeleteConfirmDelete');
        this.setState({ showModalDelete: false });
        this.setState({ editedId: 0 });
        DataStore.deleteMaterial(this.props.terminal, this.state.loggedUser.user_id, this.state.deletingId);
        console.log(this.state.deletingId);
    }


    modalCreateClose(event) {
        console.log('modalCreateClose');
        this.setState({ showModalCreate: false });
    }

    modalCreateCancel(event) {
        console.log('modalCreateCancel');
        this.setState({ showModalCreate: false });
    }

    modalCreateAdd(name, type, thickness, width) {
        console.log('modalCreateAdd');
        this.setState({ showModalCreate: false });
        DataStore.createMaterial(this.props.terminal, this.state.loggedUser.user_id, name, type, thickness, width);
        console.log(name + ' | ' + width);
    }


    modalWrongOperationClose(event) {
        console.log('modalWrongOperationClose');
        this.setState({ showModalWrongOperation: false });
    }



    render() {
        var userCanEdit = DataStore.checkMinimumAuthorization(this.state.loggedUser, 'planner');
        return (
            <div className={"text-blue-white"}>
                <div className={"row"}>
                    <Card.Body>
                        <Table className="text-white">
                            <thead>
                                <tr>
                                    <th width="10%">ID</th>
                                    <th width="20%">NÁZEV</th>
                                    <th width="10%">TYP</th>
                                    <th width="15%">TLOUŠŤKA [µm]</th>
                                    <th width="10%">ŠÍŘKA [mm]</th>
                                    <th width="35%">AKCE {userCanEdit ? <Button style={{float: "right", marginRight: "10px" }} variant="outline-success" onClick={this.handleCreateButton.bind(this)} >VYTVOŘIT<img style={{ marginLeft: "10px"}} src={ImgAdd} alt="ImgAdd" width="20px" /></Button> : ""}</th>
                                    </tr>
                            </thead>
                             <tbody>
                                {
                                    this.state.materialsData.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{item.material_id}</td>
                                                {this.state.editedId==item.material_id
                                                    ? <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={item.material_name} onChange={(event) => this.handleChangeName(event)} /></td>
                                                    : <td>{item.material_name}</td>
                                                }
                                                {this.state.editedId==item.material_id
                                                    ? <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={item.material_type} onChange={(event) => this.handleChangeType(event)} /></td>
                                                    : <td>{item.material_type}</td>
                                                }

                                                {this.state.editedId==item.material_id
                                                    ? <td><Form.Control className={this.state.styleFormThickness} style={{ width: '100%', textAlign: 'center' }} value={item.material_thickness} onChange={(event) => this.handleChangeThickness(event)} /></td>
                                                    : <td>{item.material_thickness}</td>
                                                }
                                                {this.state.editedId==item.material_id
                                                    ? <td><Form.Control className={this.state.styleFormWidth} style={{ width: '100%', textAlign: 'center' }} value={item.material_width} onChange={(event) => this.handleChangeWidth(event)} /></td>
                                                    : <td>{item.material_width}</td>
                                                }
                                                <td>
                                                    { userCanEdit ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-danger" onClick={(event) => this.handleDeleteButton(event, item.material_id)} >SMAZAT<img style={{ marginLeft: "10px"}} src={ImgDelete} alt="ImgDelete" width="20px" /></Button> : "" }
                                                    {this.state.editedId==0 && userCanEdit
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-warning" onClick={(event) => this.handleEditButton(event, item.material_id, item.material_name, item.material_type, item.material_thickness, item.material_width)}>EDITOVAT<img style={{ marginLeft: "10px"}} src={ImgEdit} alt="ImgEdit" width="20px" /></Button>
                                                        : null
                                                    }
                                                    {this.state.editedId==item.material_id && userCanEdit
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-warning" onClick={this.handleCancelButton.bind(this)} >ZRUŠIT<img style={{ marginLeft: "10px"}} src={ImgCancel} alt="ImgCancel" width="20px" /></Button>
                                                        : null
                                                    }
                                                    {this.state.editedId==item.material_id && userCanEdit
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-success" onClick={this.handleSaveButton.bind(this)} >ULOŽIT<img style={{ marginLeft: "10px"}} src={ImgSave} alt="ImgSave" width="20px" /></Button>
                                                        : null
                                                    }
                                                    
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </div>
                <ModalPassword showModal={this.state.showModalPassword} checkPinCode={this.checkPinCode.bind(this) } modalTaggle={this.pinCodeModalTaggle.bind(this)} />
                <ModalDeleteConfirm showModal={this.state.showModalDelete} modalClose={this.modalDeleteConfirmClose.bind(this)} modalCancel={this.modalDeleteConfirmCancel.bind(this)} modalDelete={this.modalDeleteConfirmDelete.bind(this)} />
                <ModalCreateMaterial showModal={this.state.showModalCreate} modalClose={this.modalCreateClose.bind(this)} modalCancel={this.modalCreateCancel.bind(this)} modalAdd={this.modalCreateAdd.bind(this)} />
                <ModalWrongOperation showModal={this.state.showModalWrongOperation} textOfOperation={this.state.textOfWrongOperation} modalClose={this.modalWrongOperationClose.bind(this)}/>
            </div>
        );
    }
}
