import React from 'react';
import Button from 'react-bootstrap/Button';
import Chart from 'react-apexcharts'
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Accordion from 'react-bootstrap/Accordion';

export default class DataloggerDashboardTechnologyDetail extends React.Component {

    constructor() {
        super();
    }

    render() {
        var info = <div></div>;
        var state = <Badge variant="danger"  style={{ animation: "2s cubic-bezier(0, 1.79, 1, 1.02) 0s infinite normal none running blinker"}}>NOT READY</Badge>;
        var display = "none";
        if (this.props.showMore) display = "";
        if (this.props.type == 'boge') {
            if (typeof this.props.values != 'undefined') {
                info =
                    <div style={{ display, fontSize: "10px", textAlign: "left", padding: "10px", height: "240px" }} >
                        <div>COMMUNICATION TIME: <strong>{this.props.values.time}</strong></div>
                        <div>BLS pressure: <strong>{this.props.values.value1}</strong></div>
                        <div>COMPRESSOR EFFICIENCY: <strong>{this.props.values.value2}</strong></div>
                        <div>TEMPERATURE: <strong>{this.props.values.value3}</strong></div>
                        <div>NET PRESSURE: <strong>{this.props.values.value4}</strong></div>
                        <div>SYSTEM PRESSURE: <strong>{this.props.values.value5}</strong></div>
                        <div>HOURS UNTIL COMPRESSOR SERVICE [h]: <strong>{this.props.values.value8}</strong></div>
                        <div>HOURS UNTIL MOTOR SERVICE [h]: <strong>{this.props.values.value9}</strong></div>
                        <div>FAULT INDICATION: <strong>{this.props.values.value10}</strong></div>
                        <div>READY FOR OPERATION: <strong>{this.props.values.value11}</strong></div>
                        <div>IN OPERATION: <strong>{this.props.values.value12}</strong></div>
                        <div>LOAD OPERATION: <strong>{this.props.values.value13}</strong></div>
                        <div>MAINTENANCE INDICATION: <strong>{this.props.values.value14}</strong></div>
                    </div>
                if (this.props.values.value11) state = <Badge variant="success">READY</Badge>;
                if (this.props.values.value14) state = <Badge variant="warning">MAINTENANCE</Badge>;
            }
        }

        if (this.props.type == 'danfoss') {
            if (typeof this.props.values != 'undefined') {
                info =
                    <div style={{ display, fontSize: "10px", textAlign: "left", padding: "10px", height: "240px" }} >
                        <div>COMMUNICATION TIME: <strong>{this.props.values.time}</strong></div>
                        <div>CONTROL READY: <strong>{this.props.values.value1 ? "TRUE" : "FALSE"}</strong></div>
                        <div>FREQUENCY CONVERTER READY: <strong>{this.props.values.value2 ? "TRUE" : "FALSE"}</strong></div>
                        <div>SAFETY CLOSED: <strong>{this.props.values.value3 ? "TRUE" : "FALSE"}</strong></div>
                        <div>ALARM: <strong>{this.props.values.value4 ? "TRUE" : "FALSE"}</strong></div>
                        <div>AUTO MODE: <strong>{this.props.values.value5 ? "TRUE" : "FALSE"}</strong></div>
                        <div>IN FREQUENCY RANGE: <strong>{this.props.values.value6 ? "TRUE" : "FALSE"}</strong></div>
                        <div>RUNNING: <strong>{this.props.values.value7 ? "TRUE" : "FALSE"}</strong></div>
                        <div>VOLTAGE WARNING: <strong>{this.props.values.value8 ? "TRUE" : "FALSE"}</strong></div>
                        <div>CURRENT LIMIT: <strong>{this.props.values.value9 ? "TRUE" : "FALSE"}</strong></div>
                        <div>THERMAL WARNING: <strong>{this.props.values.value10 ? "TRUE" : "FALSE"}</strong></div>
                    </div>
                if (this.props.values.value1) state = <Badge variant="success">READY</Badge>;
                if (this.props.values.value8) state = <Badge variant="warning">VOLTAGE WARNING</Badge>;
                if (this.props.values.value9) state = <Badge variant="warning">CURENT LIMIT WARNING</Badge>;
                if (this.props.values.value10) state = <Badge variant="warning">THERMAL WARNING</Badge>;
                if (this.props.values.value4) state = <Badge variant="warning">ALARM</Badge>;
            }
        }




        return (
            <div>
                <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                    <div className={"card-header bg-dark"} ><h5>{this.props.name}</h5></div>
                    <h6  style={{ textAlign: 'left', padding: "10px" }}>STATE:<div style={{ float: "right"}} >{state}</div></h6>
                    {info}
                </div>

            </div>
        );
    }
}
