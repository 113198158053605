import React from 'react';
import DataStore from "../../stores/DataStore";
import DataloggerDashboardDetail from './DataloggerDashboardDetail';
import DataloggerDashboardTechnologyDetail from './DataloggerDashboardTechnologyDetail';
import ModalPassword from './ModalPassword';
import ModalInOperation from './ModalInOperation';
import ModalConfirmMaintenance from './ModalConfirmMaintenance';
import ModalConfirmMaintenance2 from './ModalConfirmMaintenance2';


import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';

const pageName = 'DataloggerDashboard.js';

export default class DataloggerDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedUser: {},
            showModalPassword: false,
            technologies: {},
            technologyShowMore: false,
            technologyShowMoreLabel: "▽",
            machines: [],
            machine1: {},
            machine2: {},
            machine3: {},
            machine4: {},
            machine5: {},
            machine6: {},
            machine7: {},
            machine8: {},
            machine9: {},
            machine10: {},
            jobs: [],
            machine1Job: {},
            machine2Job: {},
            machine3Job: {},
            machine4Job: {},
            machine5Job: {},
            machine6Job: {},
            machine7Job: {},
            machine8Job: {},
            machine9Job: {},
            showModalInOperation: false,
            textOfOperation: "",
            carouselState: false,
            showModalMaintenance: false,
            showModalMaintenance2: false,
            maintenanceMachine: 0,

        };
    }

    componentDidMount() {
        DataStore.on("clickLogin", this.clickLogin.bind(this));
        DataStore.on("checkRfidCodeComplete", this.checkRfidCodeComplete.bind(this));
        DataStore.on("checkPinCodeComplete", this.checkPinCodeComplete.bind(this));
        DataStore.on("logout", this.logout.bind(this));
        DataStore.on("getDataloggerDashboardComplete", this.getDataloggerDashboardComplete.bind(this));
        DataStore.getDataloggerDashboard(this.props.terminal);
        console.log("DataloggerDashboard.js - componentDidMount");
        setInterval(() => {
            DataStore.getDataloggerDashboard(this.props.terminal);
        }, 60000);
        setInterval(() => {
            this.setState({ carouselState: !this.state.carouselState });
        }, 60000);
    }

    componentWillUnmount() {
        DataStore.removeListener("clickLogin", this.clickLogin);
        DataStore.removeListener("checkRfidCodeComplete", this.checkRfidCodeComplete);
        DataStore.removeListener("checkPinCodeComplete", this.checkPinCodeComplete);
        DataStore.removeListener("logout", this.logout);
        DataStore.removeListener("getDataloggerDashboardComplete", this.getDataloggerDashboardComplete);
    }

    // USER PERMISSION
    checkRfidCodeComplete(terminal, user) {
        if (this.props.terminal == terminal) {
            console.log(pageName + " - checkRfidCodeComplete terminal: " + this.props.terminal + " name: " + user.user_name);
            console.log(terminal);
            this.setState({ loggedUser: user });
            this.setState({ logoutCountdownCounter: 300 });
            this.setState({ showLogoutCountdown: true });
        }
    }

    logout(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - logout ");
            this.setState({ loggedUser: {} });
        }
    }

    clickLogin(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - login ");
            //this.setState({ loggedUser: {} });
            this.setState({ showModalPassword: true });
        }
    }

    checkPinCode(code) {
        DataStore.checkPinCode(this.props.terminal, code);
    }

    checkPinCodeComplete(terminal, user) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - checkPinCodeComplete ");
            if (user.name != "Unknown pin code") {
                this.setState({ loggedUser: user });
                this.setState({ logoutCountdownCounter: 300 });
                if (terminal != 'terminalProduction' && terminal != 'terminalEntrance') this.setState({ logoutCountdownCounter: 1800 });
                this.setState({ showLogoutCountdown: true });
                this.setState({ showModalPassword: false });
            }
        }
    }

    pinCodeModalTaggle() {
        console.log(pageName + " - pinCodeModalTaggle ");
        this.setState({ showModalPassword: !this.state.showModalPassword });
    }



    getDataloggerDashboardComplete(terminal, resultJobs, resultMachines) {
        if (this.props.terminal == terminal) {
            console.log("DataloggerDashboard.js - getDataloggerDashboardComplete terminal: " + this.props.terminal);
            console.log(resultJobs);
            console.log(resultMachines);

            this.setState({
                showModalInOperation: false,
            });

            resultMachines.map((machine, index) => {
                if (index == 0) this.setState({ machine1: machine });
                if (index == 1) this.setState({ machine2: machine });
                if (index == 2) this.setState({ machine3: machine });
                if (index == 3) this.setState({ machine4: machine });
                if (index == 4) this.setState({ machine5: machine });
                if (index == 5) this.setState({ machine6: machine });
                if (index == 6) this.setState({ machine7: machine });
                if (index == 7) this.setState({ machine8: machine });
                if (index == 8) this.setState({ machine9: machine });
                if (index == 9) this.setState({ machine10: machine });
            });
            
            var jobs = [];
            resultJobs.map((job) => {
                if (job.job_machine_id == 1) this.setState({ machine1Job: job });
                if (job.job_machine_id == 2) this.setState({ machine2Job: job });
                if (job.job_machine_id == 3) this.setState({ machine3Job: job });
                if (job.job_machine_id == 4) this.setState({ machine4Job: job });
                if (job.job_machine_id == 5) this.setState({ machine5Job: job });
                if (job.job_machine_id == 6) this.setState({ machine6Job: job });
                if (job.job_machine_id == 7) this.setState({ machine7Job: job });
                if (job.job_machine_id == 8) this.setState({ machine8Job: job });
                if (job.job_machine_id == 9) this.setState({ machine9Job: job });
            })

        }
    }

    modalConfirmMaintenanceTaggle() {
        console.log(pageName + " - modalJobReportTaggle ");
        this.setState({ showModalMaintenance: !this.state.showModalMaintenance });
    }

    showMaintenanceConfirm(maintenanceMachine) {
        if ((this.state.loggedUser.user_role == 'maintenance') || (this.state.loggedUser.user_role == 'administrator')) this.setState({ showModalMaintenance: true, maintenanceMachine });
    }

    maintenanceDoneButtonClick() {
        DataStore.resetMaintenance(this.props.terminal, this.state.maintenanceMachine, this.state.loggedUser.user_id)
        this.setState({ showModalMaintenance: !this.state.showModalMaintenance });
    }


    modalConfirmMaintenance2Taggle() {
        console.log(pageName + " - modalJobReportTaggle2 ");
        this.setState({ showModalMaintenance2: !this.state.showModalMaintenance2 });
    }

    showMaintenance2Confirm(maintenanceMachine) {
        if ((this.state.loggedUser.user_role == 'maintenance') || (this.state.loggedUser.user_role == 'administrator')) this.setState({ showModalMaintenance2: true, maintenanceMachine });
    }

    maintenance2DoneButtonClick() {
        DataStore.resetMaintenance2(this.props.terminal, this.state.maintenanceMachine, this.state.loggedUser.user_id)
        this.setState({ showModalMaintenance2: !this.state.showModalMaintenance2 });
    }



    render() {
        var dashboard;

        if (this.props.path.indexOf('dataloggerDashboardAll') > -1) {
            if (this.state.carouselState == false) {
                dashboard = 
                    <div>
                        <div >
                            <div className={"row"}>
                                <h3 style={{  border: "none", color: "white", textAlign: "center", width: "100%" }} >POPINECKÁ</h3>
                            </div>
                            <div className={"row"}>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={1} machine={this.state.machine1} job={this.state.machine1Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M1"} machineName={"GN DX3021 NOVÝ"}/>;
                                </div>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={2} machine={this.state.machine2} job={this.state.machine2Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M2"} machineName={"GN DX3021 STARÝ"}/>;
                                </div>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={3} machine={this.state.machine3} job={this.state.machine3Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M3"} machineName={"GN DX2220"}/>;
                                </div>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={5} machine={this.state.machine5} job={this.state.machine5Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M5"} machineName={"LIKAR"}/>;
                                </div>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={6} machine={this.state.machine6} job={this.state.machine6Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M6"} machineName={"GN DM1914"}/>;
                                </div>
                            </div>
                        </div>
                    </div>
            }
        if (this.state.carouselState == true) {
                dashboard = 
                    <div>
                        <div >
                            <div className={"row"}>
                                <h3 style={{  border: "none", color: "white", textAlign: "center", width: "100%" }} >MILBA</h3>
                            </div>
                            <div className={"row"}>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={7} machine={this.state.machine7} job={this.state.machine7Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M7"} machineName={"ILLIG"}/>;
                                </div>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={8} machine={this.state.machine8} job={this.state.machine8Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M8"} machineName={"ILLIG + ROBOSTACKER"}/>;
                                </div>
                                <div className={"col-25"}>
                                    <DataloggerDashboardDetail machineId={9} machine={this.state.machine9} job={this.state.machine9Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M9"} machineName={"RETROFIT PNEU"}/>;
                                </div>
                            </div>
                        </div>
                    </div>
            }        }
                
        if (this.props.path.indexOf('dataloggerDashboardPopinecka') > -1) {
            dashboard = 
                <Carousel fade interval={30000} controls={false} indicators={false} >
                    <Carousel.Item>
                        <div className={"row"}>
                            <h3 style={{  border: "none", color: "white", textAlign: "center", width: "100%" }} >POPINECKÁ</h3>
                        </div>
                        <div className={"row"}>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={1} machine={this.state.machine1} job={this.state.machine1Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M1"} machineName={"GN DX3021 NOVÝ"}/>;
                            </div>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={2} machine={this.state.machine2} job={this.state.machine2Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M2"} machineName={"GN DX3021 STARÝ"}/>;
                            </div>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={3} machine={this.state.machine3} job={this.state.machine3Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M3"} machineName={"GN DX2220"}/>;
                            </div>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={5} machine={this.state.machine5} job={this.state.machine5Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M5"} machineName={"LIKAR"}/>;
                            </div>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={6} machine={this.state.machine6} job={this.state.machine6Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M6"} machineName={"GN DM1914"}/>;
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
        }
        if (this.props.path.indexOf('dataloggerDashboardMilba') > -1) {
            dashboard = 
                <Carousel fade interval={30000} controls={false} indicators={false} >
                    <Carousel.Item>
                        <div className={"row"}>
                            <h3 style={{  border: "none", color: "white", textAlign: "center", width: "100%" }} >MILBA</h3>
                        </div>
                        <div className={"row"}>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={7} machine={this.state.machine7} job={this.state.machine7Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M7"} machineName={"ILLIG"}/>;
                            </div>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={8} machine={this.state.machine8} job={this.state.machine8Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M8"} machineName={"ILLIG + ROBOSTACKER"}/>;
                            </div>
                            <div className={"col-25"}>
                                <DataloggerDashboardDetail machineId={9} machine={this.state.machine9} job={this.state.machine9Job} showMaintenanceConfirm={this.showMaintenanceConfirm.bind(this)} showMaintenance2Confirm={this.showMaintenance2Confirm.bind(this)} machineNumber={"M9"} machineName={"RETROFIT PNEU"}/>;
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
        }


        return (
            <div>
                {dashboard}
                <ModalPassword showModal={this.state.showModalPassword} checkPinCode={this.checkPinCode.bind(this) } modalTaggle={this.pinCodeModalTaggle.bind(this)} />
                <ModalConfirmMaintenance showModal={this.state.showModalMaintenance} modalTaggle={this.modalConfirmMaintenanceTaggle.bind(this)} buttonClick={this.maintenanceDoneButtonClick.bind(this)} />
                <ModalConfirmMaintenance2 showModal={this.state.showModalMaintenance2} modalTaggle={this.modalConfirmMaintenance2Taggle.bind(this)} buttonClick={this.maintenance2DoneButtonClick.bind(this)} />
            </div>

        );
    }
}
