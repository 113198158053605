import React from 'react';
import Button from 'react-bootstrap/Button';
import Chart from 'react-apexcharts'
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';

export default class DataloggerDashboardDetail extends React.Component {

    constructor() {
        super();
    }

    jobButtonClick() {
        this.props.jobReportButtonClick(this.props.job);
    }


    showMaintenanceConfirm() {
        this.props.showMaintenanceConfirm(this.props.machineId);
    }

    showMaintenance2Confirm() {
        this.props.showMaintenance2Confirm(this.props.machineId);
    }



    render() {

        const stopReasonNames = [
            "WAITING FOR REASON SELECT",
            "TOOL CHANGE",
            "ROLL CHANGE",
            "STACKER",
            "TOOL CHANGE WITH TOP PLATE",
            "FOIL BROKE",
            "HOLES",
            "TAPE TOOL",
            "TEMPERATURE ISSUES",
            "CLEAN PLASTIC ROLES",
            "TRANSPORT",
            "REPAIR / MAINTENANCE",
            "OTHER",
            "PLASTIC IN ROLLERS",
            "DON'T KNOW",
            "BROKEN STRIP",
            "DID NOT SEE THE PROBLEM",
            "CHANGE POSITION TOOL",
            "START WITHOUT PLASTIC",
            "CONFIGURING KNIVES",
            "EMPTY PLACE",
            "TELESCOPING",
            "PLASTIC ON TABLE",
            "MACHINE ERROR",
            "MACHINE STOPPED",
            "SMART CAMERA STOP",
            "",
            "",
            "",
            "",
            "SHIFT STOP",
            "SHIFT STOP DETECTED",
        ];

        var performancePercent = 0;
        var badgeOnline = <h4 style={{ float: "left"}}><Badge variant="danger">OFFLINE</Badge></h4>;
        var stopReason = <h6 style={{ width: "100%"}} ><Badge variant="warning" style={{ width: "100%", visibility: "hidden"}}>-</Badge></h6>;
        var badgeRun;
        var jobPartsDone = 0;
        var jobPackagesDone = 0;
        var jobPartsPlanned = 0;
        var jobPackagesPlanned = 0;
        var jobWeightPlanned = 0;
        var expectedQTY = 0;
        var productionRemainsMinutesAll = 0;
        var jobName;
        if ((typeof this.props.machine != 'undefined')) {
            if (this.props.machine.communicationOk == 1) jobName = <h4 style={{ textAlign: "center", width: "100%", animation: "2s cubic-bezier(0, 1.79, 1, 1.02) 0s infinite normal none running blinker"}}><Badge variant="danger">JOB NEAKTIVNí</Badge></h4>;
        }
        var jobPlan = <h5 style={{ textAlign: "center" }}>KARTÓNY: <br/> KUSY: <br/> VÁHA:</h5>;
        var jobDone = <h5 style={{ textAlign: "center" }}>KARTÓNY: <br/> KUSY: </h5>;
        var toolNameElement = <h4 style={{ width: "100%", textAlign: "center" }}><Badge  style={{ width: "100%", paddingLeft: "0", paddingRight: "0" }} variant="success"></Badge></h4>;
        var materialNamelement = <h4 style={{ width: "100%", textAlign: "center" }}><Badge  style={{ width: "100%", paddingLeft: "0", paddingRight: "0" }} variant="success"></Badge></h4>;
        var norma = 0;
        var maxCycles = <h5 style={{ textAlign: "center", width: "100%" }}>0</h5>;
        var noteElement = <h5 style={{ textAlign: "center", width: "100%" }}></h5>;
        var actualCycles = <h5 style={{ textAlign: "center", width: "100%" }}>0</h5>;
        var maintenanceElement = <h5 style={{ textAlign: "center", width: "100%" }}>0</h5>;
        var maintenance2Element = <h5 style={{ textAlign: "center", width: "100%" }}>0</h5>;

        if ((typeof this.props.job != 'undefined') && this.props.job != {}) {
            noteElement = <h5 style={{ textAlign: "center", width: "100%" }}>{this.props.job.job_note}</h5>;
            if (typeof this.props.job.tool_name != 'undefined') {
                toolNameElement = <h4 style={{ width: "100%", textAlign: "center" }}><Badge variant="success" style={{ width: "100%", paddingLeft: "0", paddingRight: "0" }} >{this.props.job.tool_name}</Badge></h4>;
            }
            if (typeof this.props.job.material_type != 'undefined' && typeof this.props.job.material_width != 'undefined' && typeof this.props.job.material_thickness != 'undefined') {
                materialNamelement = <h4 style={{ width: "100%", textAlign: "center" }}><Badge variant="success" style={{ width: "100%", paddingLeft: "0", paddingRight: "0" }} >{this.props.job.material_type + ' ' + this.props.job.material_width + 'mm x ' + this.props.job.material_thickness + 'µm'}</Badge></h4>;
            }
            if (this.props.job.job_name != '' && typeof this.props.job.job_name != 'undefined') jobName = <h4 style={{ width: "100%", textAlign: "center" }}><Badge variant="outline-success" style={{ width: "100%", paddingLeft: "0", paddingRight: "0" }} >{this.props.job.job_name}</Badge></h4>;
            var progressPercent = Math.round(100 / this.props.job.job_boxes * this.props.job.job_cycles_on_machine * this.props.job.tool_parts_count / this.props.job.recipe_parts_per_package);
            var jobProgress = <ProgressBar now={progressPercent} label={`${progressPercent}%`}/>
            jobPartsPlanned = this.props.job.job_boxes * this.props.job.recipe_parts_per_package;
            jobPackagesPlanned = Number(this.props.job.job_boxes);
            jobWeightPlanned = Math.round(jobPartsPlanned * this.props.job.recipe_parts_per_kilogram / 1000);
            jobPartsDone = this.props.job.job_cycles_on_machine * this.props.job.tool_parts_count;
            jobPackagesDone = Math.round(this.props.job.job_cycles_on_machine * this.props.job.tool_parts_count / this.props.job.recipe_parts_per_package * 10) / 10;
            expectedQTY = this.props.job.ExpectedQTY;
            jobPlan = <table><tr style={{ padding: "0px"}}><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5 style={{ textAlign: "right" }}>KARTÓNY:</h5></td><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5>{jobPackagesPlanned.toLocaleString()}</h5></td></tr><tr style={{ padding: "0px"}}><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5 style={{ textAlign: "right" }}>KUSY:</h5></td><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5>{jobPartsPlanned.toLocaleString()}</h5></td></tr><tr style={{ padding: "0px"}}><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5 style={{ textAlign: "right" }}>VÁHA:</h5></td><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5>{jobWeightPlanned.toLocaleString()} kg</h5></td></tr></table>;
            jobDone = <table><tr style={{ padding: "0px"}}><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5 style={{ textAlign: "right" }}>KARTÓNY:</h5></td><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5>{jobPackagesDone.toLocaleString()}</h5></td></tr><tr style={{ padding: "0px"}}><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5 style={{ textAlign: "right" }}>KUSY:</h5></td><td style={{borderTop: "none", padding: "0px", paddingLeft: "15px"}}><h5>{jobPartsDone.toLocaleString()}</h5></td></tr></table>;
            norma = Math.round(this.props.job.recipe_parts_per_shift / 7.5 / 60 * 10 / this.props.job.tool_parts_count) / 10;
            maxCycles = <h5 style={{ textAlign: "center", width: "100%" }}>{norma}</h5>;
            //jobWeight = <h6 style={{ textAlign: "center" }}>PLAN: {Math.round(jobPartsPlanned * expectedQTY * this.props.job.recipe.weightIncludeWaste)} kg <br/> DONE: {Math.round(jobPartsDone * expectedQTY * this.props.job.recipe.weightIncludeWaste)} kg</h6>;
            //console.log(this.props.job);
            if ((typeof this.props.machine != 'undefined')) {
                if (this.props.machine.cyclesPerMinute != 0) productionRemainsMinutesAll = Math.round(((jobPartsPlanned - jobPartsDone) / this.props.job.tool_parts_count) / this.props.machine.cyclesPerMinute);
            }
            if (norma > 0) performancePercent = Math.round(100 / norma * this.props.machine.cyclesPerMinute);
        }

        if ((typeof this.props.machine != 'undefined')) {
            if ((this.props.machine.running == 0) || (this.props.machine.communicationOk == 0)) performancePercent = 0;
            if (this.props.machine.communicationOk == 0) badgeOnline = <h4 style={{ float: "left"}}><Badge variant="danger">OFFLINE</Badge></h4>;
            if (this.props.machine.communicationOk == 1) badgeOnline = <h4 style={{ float: "left"}}><Badge variant="success">ONLINE</Badge></h4>;
            if (this.props.machine.running == 0) {
                badgeRun = <h4 style={{ float: "right"}}><Badge variant="danger">STOP</Badge></h4>;
                stopReason = <h6 style={{ width: "100%"}} ><Badge variant="warning" style={{ width: "100%"}}>{stopReasonNames[this.props.machine.sample_stop_reason_id]}</Badge></h6>;

            }
            if (this.props.machine.running == 1) badgeRun = <h4 style={{ float: "right"}}><Badge variant="success">RUN</Badge></h4>;
            actualCycles = <h5 style={{ textAlign: "center", width: "100%" }}>{Math.trunc(this.props.machine.cyclesPerMinute)}</h5>;
            var maintenanceDays = 90 - (Math.round(((this.props.machine.minutesRunningTotal - this.props.machine.minutesLastMaintenance) / 60 / 24)));
            var maintenance2Days = 180 - (Math.round(((this.props.machine.minutesRunningTotal - this.props.machine.minutesLastMaintenance2) / 60 / 24)));
            maintenanceElement = <Button style={{ paddingLeft: "5px", paddingRight: "5px" }} onClick={this.showMaintenanceConfirm.bind(this)} variant={ maintenanceDays > 7 ? "outline-success" :"outline-danger"} ><div>{"PRAVID. ÚDRŽBA:"}</div><div>{maintenanceDays + " dní"}</div></Button>;
            maintenance2Element = <Button style={{ paddingLeft: "5px", paddingRight: "5px" }} onClick={this.showMaintenance2Confirm.bind(this)} variant={maintenance2Days > 7 ? "outline-success" :"outline-danger"} ><div>{"KOMPLET. ÚDRŽBA:"}</div><div>{maintenance2Days + " dní"}</div></Button>;
        }
        //console.log(this.props.job);
        //console.log(this.props.machine);

        var productionRemainsMinutes = Math.round(productionRemainsMinutesAll % 60);
        var productionRemainsHours = Math.round(productionRemainsMinutesAll / 60);
        var productionRemains = <h5 style={{ textAlign: "center" }}>{productionRemainsHours} h {productionRemainsMinutes} m</h5>;


        var tempPercent = Math.tanh((100 - performancePercent) / 20) * 100;
        if (tempPercent > 100) tempPercent = 100;
        if (tempPercent < 0) tempPercent = 0;
        var performanceRgb = 'rgb(' + Math.round(tempPercent * 2.55) + ',' + (Math.round((100 - tempPercent) * 2.55)) + ',0)';


        var seriesPerformance = [performancePercent];
        var optionsPerformance = {
            chart: {},
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    dataLabels: {
                        name: {
                            fontSize: '16px',
                            color: '#FFFFFF',
                            offsetY: -120
                        },
                        value: {
                            offsetY: 0,
                            fontSize: '22px',
                            color: '#FFFFFF',
                            formatter: function(val) {
                                return val + "%";
                            }
                        }
                    },
                    track: {
                        background: "#fefefe",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                        }
                    },
                }
            },
            colors: [performanceRgb],
            stroke: {
                lineCap: 'round'
            },
            labels: ['AKTUÁLNÍ VÝKON'],
        };

        var cardClass = 'card mb-3 text-white '

        return (
            <div>
                <div className={cardClass} style={{  border: "none" }}>
                    <div className={"card-header bg-dark"} id="headingOne" style={{ textAlign: 'center', color: 'white'}} >
                        <div className={"row"} >
                            <div className={"col-4"} style={{ padding: "0"}} >
                                {badgeOnline}
                            </div>
                            <div className={"col-4"} style={{ padding: "0"}} >
                                <h1 style={{ lineHeight: "0.7"}} >{this.props.machineNumber}</h1>
                            </div>
                            <div className={"col-4"} style={{ padding: "0"}} >
                                {badgeRun}
                            </div>
                        </div>
                        <div className={"row"} >
                            <h5 style={{ width: "100%", margin: "0"}} >{this.props.machineName}</h5>
                        </div>
                    </div>
                    <div className={"card-body bg-dark"} style={{  border: "none", padding: "0px"  }}>
                        <Chart options={optionsPerformance} series={seriesPerformance} type="radialBar" height="300" style={{  marginBottom: "-20px" }} />
                        <Table size="sm" className="text-white">
                            <tbody style={{ margin: "2px"}}>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >FORMA</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{toolNameElement}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >MATERIÁL</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{materialNamelement}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >POZNÁMKA</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{noteElement}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >NÁZEV</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{jobName}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >PLÁN</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{jobPlan}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >HOTOVO</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{jobDone}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >PROGRESS</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{jobProgress}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >ZBÝVÁ</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{productionRemains}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >NORMA</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{maxCycles}</td>
                                </tr>
                                <tr>
                                    <td style={{  verticalAlign: "middle" }}><h6 style={{ fontWeight: "bold" }} >RYCHLOST</h6></td>
                                    <td style={{  verticalAlign: "middle" }}>{actualCycles}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className={"card-footer bg-dark"} id="headingOne" style={{ textAlign: 'center', color: 'white', margin: "0px", padding: "0px"}} >
                        <div className="row">
                            <div className="col-6" style={{  verticalAlign: "middle", margin: "0px", padding: "0px" }}>{maintenanceElement}</div>
                            <div className="col-6" style={{  verticalAlign: "middle", margin: "0px", padding: "0px" }}>{maintenance2Element}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
