import React from 'react';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalWrongOperation extends React.Component {
    constructor() {
        super();
        this.state = {
        }
        
    }

    componentDidMount() {}

    componentWillUnmount() {}

    modalClose() {
        console.log("ModalWrongOperation.js - modalTaggle ");
        this.props.modalClose();
    }


    render() {
        //var lang = cookies.get('lang');

        return (
            <div className={"text-blue-white"}>
                <Modal show={this.props.showModal} onHide={this.modalClose.bind(this)} dialogClassName="in-operation-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>WRONG OPERATION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ wordBreak: "break-all", fontSize: "small"}}>{this.props.textOfOperation}</p>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
