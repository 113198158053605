import React from 'react';
import ImgDelete from '../images/IconDelete.png';
import ImgCancel from '../images/IconCancel.png';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalDeleteConfirm extends React.Component {
    constructor() {
        super();
        this.state = {
        }
        
    }

    componentDidMount() {}

    componentWillUnmount() {}

    modalTaggle() {
        console.log("ModalDeleteConfirm.js - modalTaggle ");
        this.props.modalClose();
    }

    handleDeleteButton() {
        console.log("ModalDeleteConfirm.js - handleDeleteButton ");
        this.props.modalDelete();
    }

    handleCancelButton() {
        console.log("ModalDeleteConfirm.js - handleCancelButton ");
        this.props.modalCancel();
    }


    render() {
        //var lang = cookies.get('lang');

        return (
            <div className={"text-blue-white"}>
                <Modal show={this.props.showModal} onHide={this.modalTaggle.bind(this)} dialogClassName="in-operation-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>OPRAVDU SI PŘEJETE TUTO POLOŽKU SMAZAT?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button style={{float: "right", marginRight: "10px" }} variant="danger" onClick={this.handleDeleteButton.bind(this)} >SMAZAT<img style={{ marginLeft: "10px"}} src={ImgDelete} alt="ImgDelete" width="20px" /></Button>
                        <Button style={{float: "right", marginRight: "10px" }} variant="secondary" onClick={this.handleCancelButton.bind(this)} >ZRUŠIT<img style={{ marginLeft: "10px"}} src={ImgCancel} alt="ImgCancel" width="20px" /></Button>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
