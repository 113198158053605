import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './index.css';

import Menu from './components/Menu.js';
import DataloggerDashboard from './components/pages/DataloggerDashboard.js';
import Recipes from './components/pages/Recipes.js';
import Tools from './components/pages/Tools.js';
import Materials from './components/pages/Materials.js';
import Customers from './components/pages/Customers.js';
import Jobs from './components/pages/Jobs.js';
import JobsHistory from './components/pages/JobsHistory.js';
import DataLoggerPanel from './components/pages/DataLoggerPanel.js';
import Cameras from './components/pages/Cameras.js';
import EventLogs from './components/pages/EventLogs.js';


const App = () => (
  <div>
    <nav>
      <Menu path=":terminal/:page" />
    </nav>
    <Router>
      <Home path="/" message="hi">
        hi
      </Home>
      <DataloggerDashboard path=":terminal/dataloggerDashboard" page=":page" />
      <DataloggerDashboard path=":terminal/dataloggerDashboardAll" page=":page" />
      <DataloggerDashboard path=":terminal/dataloggerDashboardMilba" page=":page" />
      <DataloggerDashboard path=":terminal/dataloggerDashboardPopinecka" page=":page" />
      <Recipes path=":terminal/receptury" />
      <Tools path=":terminal/formy" />
      <Materials path=":terminal/material" />
      <Customers path=":terminal/zakaznici" />
      <Jobs path=":terminal/jobs" />
      <JobsHistory path=":terminal/jobsHistory" />
      <DataLoggerPanel path=":terminal/dataLoggerPanel" />
      <Cameras path=":terminal/cameras" />
      <EventLogs path=":terminal/eventLogs" />

    </Router>
  </div>
)

const Home = ({ path, message }) => (
  <div>
    <h2>
      {message} {path}
    </h2>
  </div>
)

ReactDOM.render(<App />, document.getElementById('root'))
