import React from 'react';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalConfirmMaintenance2 extends React.Component {
    constructor() {
        super();
        this.state = {
        }
        
    }

    componentDidMount() {}

    componentWillUnmount() {}

    modalTaggle() {
        console.log("ModalConfirmMaintenance.js - modalTaggle ");
        this.props.modalTaggle();
    }

    buttonClick() {
        console.log("ModalConfirmMaintenance.js - buttonClick ");
        this.props.buttonClick();
    }



    render() {
        //var lang = cookies.get('lang');

        return (
            <div className={"text-blue-white"}>
                <Modal show={this.props.showModal} onHide={this.modalTaggle.bind(this)} dialogClassName="in-operation-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>PROVEDENÍ KOMPLETNÍ ÚDRŽBY</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"row"}>
                            <ListGroup as="ul" style={{width: "100%", margin: "15px"}} >
                                <ListGroup.Item as="li" variant='dark'style={{padding: "10px"}}><h5>Potvrďte, že byly provedeny tyto úkony:</h5></ListGroup.Item>
                                <ListGroup.Item as="li" style={{padding: "10px"}}>- Seřízení stroje</ListGroup.Item>
                                <ListGroup.Item as="li" style={{padding: "10px"}}>- Kontrola mechanizmů</ListGroup.Item>
                            </ListGroup>
                        </div>
                        <div className={"row"}>
                            <Button style={{ }} variant="success" onClick={this.buttonClick.bind(this)} >PROVÉST ÚDRŽBU</Button>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
