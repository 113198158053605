import React from 'react';
import DataStore from "../../stores/DataStore";
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import dateFormat from 'dateformat';

export default class JobDetail extends React.Component {

    constructor() {
        super();
        this.handleJobReportButton = this.handleJobReportButton.bind(this);
    }

    handleJobReportButton() {
        console.log("JobDetail.js - handleJobReportButton: ");
        this.props.jobReportButtonClick(this.props.job);
    }

    handleStartJobButton() {
        console.log("JobDetail.js - handleStartJobButton: " + this.props.job.Job_id);
        this.props.startJob(this.props.job.job_id);
    }

    handleStopJobButton() {
        console.log("JobDetail.js - handleStopJobButton: " + this.props.job.Job_id);
        this.props.stopJob(this.props.job.job_id);
    }

    handleFinishJobButton() {
        console.log("JobDetail.js - handleFinishJobButton: " + this.props.job.Job_id);
        this.props.finishJob(this.props.job.job_id);
    }

    handleEditJobButton() {
        console.log("JobDetail.js - handleEditJobButton: " + this.props.job.Job_id);
        this.props.editJob(this.props.job);
    }

    handleJobQualityCheckButton() {
        console.log("JobDetail.js - handleJobQualityCheckButton: " + this.props.job.Job_id);
        this.props.jobQualityCheck(this.props.job);
    }


    render() {

        var plannedDate = this.props.job.job_dt_plan != null ? dateFormat(new Date(this.props.job.job_dt_plan), "yyyy-mm-dd HH:MM") : "";
        var startDate = this.props.job.job_dt_start != null ? dateFormat(new Date(this.props.job.job_dt_start), "yyyy-mm-dd HH:MM") : "";
        var finishDate = this.props.job.job_dt_stop != null ? dateFormat(new Date(this.props.job.job_dt_stop), "yyyy-mm-dd HH:MM") : "";

        var jobStatus = '';
        var cardClass = 'jobDetailDiv card mb-3 '
        if (this.props.job.job_active == 0 && this.props.job.job_dt_start == null) {
            jobStatus = 'NEAKTIVNÍ'
            cardClass += 'bg-secondary';
        }
        else if (this.props.job.job_active == 0 && this.props.job.job_dt_start != null && this.props.job.job_dt_stop == null) {
            jobStatus = 'POZASTAVENO'
            cardClass += 'bg-light';
        }
        else if (this.props.job.job_active == 1 && this.props.job.job_dt_start != null) {
            jobStatus = 'AKTIVNÍ'
            cardClass += 'bg-warning';
        }
        else if (this.props.job.job_active == 0 && this.props.job.job_dt_stop != null) {
            jobStatus = 'UKONČENO'
            cardClass += 'bg-success';
        }
        var jobReportButton;
        if (this.props.job.job_dt_start != null) {
            jobReportButton = <Button style={{float: "right" }}  variant="outline-dark" onClick={this.handleJobReportButton} >REPORT</Button>;
        }

        var jobButtonActionStart;
        var jobButtonActionStop;
        var jobButtonActionFinish;
        var jobButtonActionEdit;
        var jobButtonQCH;

        if (DataStore.checkMinimumAuthorization(this.props.loggedUser, 'shiftleader')) {
            jobButtonQCH = <Button style={{ }} onClick={this.handleJobQualityCheckButton.bind(this)} variant="danger" >TEST KVALITY</Button>;
        }
        if (DataStore.checkMinimumAuthorization(this.props.loggedUser, 'planner')) {
            jobButtonActionEdit = <Button style={{ }} onClick={this.handleEditJobButton.bind(this)} variant="dark" >EDIT</Button>;
        }
        if (DataStore.checkMinimumAuthorization(this.props.loggedUser, 'shiftleader')) {
            if (!this.props.job.job_active) {
                jobButtonActionStart = <Button style={{ }} onClick={this.handleStartJobButton.bind(this)} variant="success" >START</Button>;
            }
        }
        if (DataStore.checkMinimumAuthorization(this.props.loggedUser, 'shiftleader')) {
            if (this.props.job.job_active) {
                jobButtonActionStop = <Button style={{ }} onClick={this.handleStopJobButton.bind(this)} variant="danger" >STOP</Button>;
            }
        }
        if (DataStore.checkMinimumAuthorization(this.props.loggedUser, 'shiftleader')) {
            if ((!this.props.job.job_active) && (this.props.job.job_dt_start != null) && (this.props.job.job_dt_stop == null)) {
                jobButtonActionFinish = <Button style={{ }} onClick={this.handleFinishJobButton.bind(this)} variant="danger" >KONEC</Button>;
            }
        }


        return (
            <div>
                <div className={cardClass} >
                    <div className={"card-header"} data-toggle="collapse" id="headingOne" data-target="#collapseOne" >
                        <div className={"row"} style={{ textAlign: "center" }}>
                            <div style={{ textAlign: "center", width: '100%'}}>
                                <h5><strong>{this.props.job.job_name }</strong></h5>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <Badge pill variant="dark" style={{  }}>{jobStatus}</Badge>
                            </div>
                            <div className={"col-6"} style={{ padding: "0px" }} >
                                {jobReportButton}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12"} style={{ textAlign: "center" }}>
                                {jobButtonQCH}
                            </div>
                        </div>
                    </div>
                    <div className={"card-body"}>
                        <h5 className="card-title" style={{ fontWeight: 800}} >DETAIL</h5>
                        <div><span style={{ float: "left" }} ><strong>Forma: </strong></span><span style={{ float: "right" }} > {this.props.job.tool_name}</span></div>
                        <div><span style={{ float: "left" }} ><strong>Materiál: </strong></span><span style={{ float: "right" }} > {this.props.job.material_type + ' ' + this.props.job.material_width + 'mm x ' + this.props.job.material_thickness + 'µm'}</span></div>
                        <div><span style={{ float: "left" }} ><strong>Kusů v Kartonu: </strong></span><span style={{ float: "right" }} > {this.props.job.recipe_parts_per_package}</span></div>
                        <div><span style={{ float: "left" }} ><strong>Typ Kartonu: </strong></span><span style={{ float: "right" }} > {this.props.job.recipe_cardboard}</span></div>
                        <div><hr/></div>
                        <div><span style={{ float: "left" }} ><strong>Kartonů: </strong></span><span style={{ float: "right" }} > {this.props.job.job_boxes}</span></div>
                        <div><span style={{ float: "left" }} ><strong>Plán:</strong></span><span style={{ float: "right" }} >{plannedDate}</span></div>
                        <div><span style={{ float: "left" }} ><strong>Start:</strong></span><span style={{ float: "right" }} >{startDate}</span></div>
                        <div><span style={{ float: "left" }} ><strong>Konec:</strong></span><span style={{ float: "right" }} >{finishDate}</span></div>
                        <div><hr/></div>
                        {this.props.job.job_note != '' && this.props.job.job_note != null ? <div><span style={{ float: "left" }} ><strong>Poznámka:</strong></span></div> : null}
                        <div><span style={{ float: "left" }} >{this.props.job.job_note}</span></div>
                        {this.props.job.job_divider_note != '' && this.props.job.job_divider_note != null ? <div><span style={{ float: "left" }} ><strong>Výměna rozdělovníku:</strong></span></div> : null}
                        <div><span style={{ float: "left" }} >{this.props.job.job_divider_note}</span></div>
                    </div>
                    <div className={"card-footer bg-transparent"}>
                        <div className={"row"}>
                            <div className={"col-4"} style={{ textAlign: "center", padding: 0 }} >
                                {jobButtonActionStart}
                            </div>
                            <div className={"col-4"} style={{ textAlign: "center", padding: 0 }} >
                                {jobButtonActionEdit}
                            </div>
                            <div className={"col-4"} style={{ textAlign: "center", padding: 0 }} >
                                {jobButtonActionStop}
                                {jobButtonActionFinish}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
