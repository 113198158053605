import React from 'react';
import DataStore from "../../stores/DataStore";
import JobDetail from './JobDetail';
import ModalPassword from './ModalPassword';
import ModalJobCreate from './ModalJobCreate';
import ModalJobEdit from './ModalJobEdit';
import ModalJobReport from './ModalJobReport';
import ModalWrongOperation from './ModalWrongOperation';
import ModalJobQualityCheck from './ModalJobQualityCheck';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
const pageName = ' Jobs.js';


export default class JobsHistory extends React.Component {
    constructor(props) {
        super(props);
        this.jobReportButtonClick = this.jobReportButtonClick.bind(this);
        this.state = {
            jobsMachines: [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ],
            newJobIndex: 0,
            recipesData: [],
            reportData: { samples: [], availability: {}, performance: {}, products: [], stopReasons: [], materials: [] },
            reportJob: {},
            activityHtml: "",
            delayReason: "",
            showModalInOperation: true,
            showModalDelete: false,
            showModalCreate: false,
            showModalEdit: false,
            showModalJobQualityCheck: false,
            editedJob: {},
            showModalJobReport: false,
            showModalWrongOperation: false,
            textOfWrongOperation: '',
            textOfOperation: "JOBS LOADING ...",
            subtaskActivities: "Activities ...",
            loggedUser: {},
            machinesScreen: 1,
            imagesHistory: [],

        };

        setInterval(() => {
            // LOGOUT
            if (this.state.logoutCountdownCounter > 0) {
                this.setState({ logoutCountdownCounter: (this.state.logoutCountdownCounter - 1) });
                if (this.state.logoutCountdownCounter == 0) {
                    this.setState({ loggedUser: {} });
                }
            }
        }, 1000);

    }

    componentDidMount() {
        DataStore.on("clickLogin", this.clickLogin.bind(this));
        DataStore.on("checkLoggedRfid", this.checkLoggedRfid.bind(this));
        DataStore.on("checkPinCodeComplete", this.checkPinCodeComplete.bind(this));
        DataStore.on("logout", this.logout.bind(this));
        DataStore.on("mysqlError", this.mysqlError.bind(this));
        DataStore.on("getJobsHistoryComplete", this.getJobsHistoryComplete.bind(this));
        DataStore.on("getJobReportDataComplete", this.getJobReportDataComplete.bind(this));
        DataStore.on("getJobImagesComplete", this.getJobImagesComplete.bind(this));
        DataStore.getJobsHistory(this.props.terminal);
    }

    componentWillUnmount() {
        DataStore.removeListener("clickLogin", this.clickLogin);
        DataStore.removeListener("checkLoggedRfid", this.checkLoggedRfid);
        DataStore.removeListener("checkPinCodeComplete", this.checkPinCodeComplete);
        DataStore.removeListener("logout", this.logout);
        DataStore.removeListener("mysqlError", this.mysqlError);
        DataStore.removeListener("getJobsHistoryComplete", this.getJobsHistoryComplete);
        DataStore.removeListener("getJobReportDataComplete", this.getJobReportDataComplete);
        DataStore.removeListener("getJobImagesComplete", this.getJobImagesComplete);
    }


    // USER PERMISSION
    checkLoggedRfid(terminal, loggedRfid, user) {
        if (this.props.terminal == terminal) {
            console.log(pageName + " - checkLoggedRfid terminal: " + this.props.terminal + " loggedRfid: " + loggedRfid + " name: " + user.name);
            console.log(terminal);
            this.setState({ loggedUser: user });
            this.setState({ logoutCountdownCounter: 300 });
            if (terminal != 'terminalProduction' && terminal != 'terminalEntrance') this.setState({ logoutCountdownCounter: 1800 });
            this.setState({ showLogoutCountdown: true });
        }
    }

    logout(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - logout ");
            this.setState({ loggedUser: {} });
        }
    }

    clickLogin(terminal) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - login ");
            //this.setState({ loggedUser: {} });
            this.setState({ showModalPassword: true });
        }
    }

    checkPinCode(code) {
        DataStore.checkPinCode(this.props.terminal, code);
    }

    checkPinCodeComplete(terminal, user) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - checkPinCodeComplete ");
            if (user.name != "Unknown pin code") {
                this.setState({ loggedUser: user });
                this.setState({ logoutCountdownCounter: 300 });
                if (terminal != 'terminalProduction' && terminal != 'terminalEntrance') this.setState({ logoutCountdownCounter: 1800 });
                this.setState({ showLogoutCountdown: true });
                this.setState({ showModalPassword: false });
            }
        }
    }

    pinCodeModalTaggle() {
        console.log(pageName + " - pinCodeModalTaggle ");
        this.setState({ showModalPassword: !this.state.showModalPassword });
    }




    getJobsHistoryComplete(terminal, jobsData, recipesData) {
        if (this.props.terminal == terminal) {
            console.log(pageName + " - getJobsHistoryComplete terminal: " + this.props.terminal);
            var jobsMachines = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            jobsData.forEach(function(job, index) {
                jobsMachines[job.job_machine_id - 1].push(job)
            })
            this.setState({
                jobsMachines,
                recipesData,
                newJobIndex: jobsData.length + 1,
                showModalInOperation: false
            });
            console.log(recipesData);
        }
    }

    mysqlError(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - mysqlError ");
            console.log(result);
            this.setState({ showModalWrongOperation: true, textOfWrongOperation: JSON.stringify(result) });
        }
    }

    modalWrongOperationClose(event) {
        console.log(pageName + 'modalWrongOperationClose');
        this.setState({ showModalWrongOperation: false });
    }

    modalCreateClose(event) {
        console.log(pageName + 'modalCreateClose');
        this.setState({ showModalCreate: false });
    }

    modalCreateCancel(event) {
        console.log(pageName + 'modalCreateCancel');
        this.setState({ showModalCreate: false });
    }

    modalCreateAdd(job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan) {
        console.log(pageName + 'modalCreateAdd');
        this.setState({ showModalCreate: false });
        DataStore.createJob(this.props.terminal, job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan);
        //console.log(name + ' | ' + recipe_tool_id);
    }

    modalEditClose(event) {
        console.log(pageName + 'modalEditClose');
        this.setState({ showModalEdit: false });
    }

    modalEditSave(job_id, job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan) {
        console.log(pageName + 'modalEditSave');
        this.setState({ showModalEdit: false });
        DataStore.updateJob(this.props.terminal, job_id, job_name, job_recipe_id, job_machine_id, job_boxes, job_dt_plan);
        //console.log(name + ' | ' + recipe_tool_id);
    }

    modalJobQualityCheckClose(event) {
        console.log(pageName + 'modalJobQualityCheckClose');
        this.setState({ showModalJobQualityCheck: false });
    }


    startJob(jobId) {
        console.log(pageName + " - startJob: " + jobId);
        DataStore.startJob(this.props.terminal, jobId);
    }

    stopJob(jobId) {
        console.log(pageName + " - stopJob: " + jobId);
        DataStore.stopJob(this.props.terminal, jobId);
    }

    finishJob(jobId) {
        console.log(pageName + " - finishJob: " + jobId);
        DataStore.finishJob(this.props.terminal, jobId);
    }

    editJob(job) {
        console.log(pageName + " - editJob: " + job.job_id);
        console.log(job);
        this.setState({ editedJob: job });
    }



    jobQualityCheck(job) {
        console.log(pageName + " - jobQualityCheck: " + job.job_id);
        console.log(job.job_name.replace(' / ', '-'));
        this.setState({ showModalJobQualityCheck: true, reportJob: job, imagesHistory: [] });
        DataStore.getJobImages(this.props.terminal, job.job_name.replace(' / ', '-'))
    }


    modalJobReportTaggle() {
        console.log(pageName + " - modalJobReportTaggle ");
        this.setState({ showModalJobReport: !this.state.showModalJobReport });
    }

    jobReportButtonClick(job) {
        console.log(pageName + " - jobReportButtonClick: " + job.JobID);
        this.setState({
            showModalJobReport: true,
            reportJob: job,
        });
        DataStore.getJobReportData(this.props.terminal, job);
    }

    getJobReportDataComplete(terminal, data) {
        if (this.props.terminal == terminal) {
            console.log(pageName + " - getJobReportDataComplete terminal: " + this.props.terminal);
            this.setState({
                reportData: data,
            });
            console.log(data);
        }
    }


    machinesScreenChange(value) {
        this.setState({ machinesScreen: value });
        console.log(value)
    }


    jobReportPrintButtonPress() {
        
    }
    
        saveTestStationPicture(job, img) {
        DataStore.saveTestStationPicture(this.props.terminal, job, img, this.state.loggedUser.user_name);
    }

    saveTestStationPicture(job, img) {
        DataStore.saveTestStationPicture(this.props.terminal, job, img, this.state.loggedUser.user_name);
    }

    getJobImagesComplete(terminal, imagesData) {
        console.log('getJobImagesComplete');
        console.log(imagesData);
        if (this.props.terminal == terminal) {
            this.setState({ imagesHistory: imagesData });
        }
    }




    render() {
        return (
            <div>
                <div className={"row"}>
                    <ToggleButtonGroup style={{ margin: "15px auto 15px auto"}} type="radio" name="options"  value={this.state.machinesScreen} onChange={this.machinesScreenChange.bind(this)} >
                      <ToggleButton value={1}>POPINECKÁ</ToggleButton>
                      <ToggleButton value={2}>MILBA</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className={"row"} hidden={this.state.machinesScreen !=1 }>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M1</h1><h5>GN DX3021 NOVÝ</h5></div>
                        </div>
                        {this.state.jobsMachines[0].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M2</h1><h5>GN DX3021 STARÝ</h5></div>
                        </div>
                        {this.state.jobsMachines[1].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M3</h1><h5>GN DX2220</h5></div>
                        </div>
                        {this.state.jobsMachines[2].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M5</h1><h5>LIKAR</h5></div>
                        </div>
                        {this.state.jobsMachines[4].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M6</h1><h5>GN DM1914</h5></div>
                        </div>
                        {this.state.jobsMachines[5].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                </div>
                <div className={"row"} hidden={this.state.machinesScreen ==1 }>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M7</h1><h5>ILLIG</h5></div>
                        </div>
                        {this.state.jobsMachines[6].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M8</h1><h5>ILLIG + ROBOSTACKER</h5></div>
                        </div>
                        {this.state.jobsMachines[7].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                    <div className={"col-25"}>
                        <div className="bg-dark" style={{ textAlign: 'center', color: 'white' }}>
                            <div className={"card-header"} ><h1>M9</h1><h5>RETROFIT PNEU</h5></div>
                        </div>
                        {this.state.jobsMachines[8].map((job, index) => {
                            return <JobDetail key={index} job={job}  jobReportButtonClick={this.jobReportButtonClick} startJob={this.startJob.bind(this)} stopJob={this.stopJob.bind(this)} finishJob={this.finishJob.bind(this)} editJob={this.editJob.bind(this)} jobQualityCheck={this.jobQualityCheck.bind(this)} loggedUser={this.state.loggedUser} />;
                        })}
                    </div>
                </div>


                <ModalJobReport modal={this.state.showModalJobReport} modalTaggle={this.modalJobReportTaggle.bind(this)} job={this.state.reportJob} reportData={this.state.reportData} jobReportPrintButtonPress={this.jobReportPrintButtonPress.bind(this) } /> 
                <ModalPassword showModal={this.state.showModalPassword} checkPinCode={this.checkPinCode.bind(this) } modalTaggle={this.pinCodeModalTaggle.bind(this)} />
                <ModalJobCreate showModal={this.state.showModalCreate} modalClose={this.modalCreateClose.bind(this)} modalCancel={this.modalCreateCancel.bind(this)} modalAdd={this.modalCreateAdd.bind(this)} recipesData={this.state.recipesData} newJobIndex={this.state.newJobIndex} />
                <ModalJobEdit showModal={this.state.showModalEdit} modalClose={this.modalEditClose.bind(this)} modalSave={this.modalEditSave.bind(this)} job={this.state.editedJob} recipesData={this.state.recipesData} />
                <ModalWrongOperation showModal={this.state.showModalWrongOperation} textOfOperation={this.state.textOfWrongOperation} modalClose={this.modalWrongOperationClose.bind(this)} />
                <ModalJobQualityCheck showModal={this.state.showModalJobQualityCheck} modalClose={this.modalJobQualityCheckClose.bind(this)} job={this.state.reportJob} saveTestStationPicture={this.saveTestStationPicture.bind(this)} imagesHistory={this.state.imagesHistory} />
            </div>

        );
    }
}
