import React from 'react';
import ImgAdd from '../images/IconAdd.png';
import ImgCancel from '../images/IconCancel.png';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalCreateMaterial extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            width: 0,
            thickness: 0,
            styleFormThickness: "",
            styleFormWidth: "",

        }
    }

    componentDidMount() {}

    componentWillUnmount() {}

    modalTaggle() {
        console.log("ModalCreateMaterial.js - modalTaggle ");
        this.props.modalClose();
    }

    handleAddButton() {
        console.log("ModalCreateMaterial.js - handleAddButton ");
        this.props.modalAdd(this.state.name, this.state.type, this.state.thickness, this.state.width);
    }

    handleCancelButton() {
        console.log("ModalCreateMaterial.js - handleCancelButton ");
        this.props.modalCancel();
    }


    handleChangeName(event) {
        console.log('ModalCreateMaterial.js - handleChangeName');
        this.setState({ name: event.target.value });
        //console.log(event.target.value);
    }

    handleChangeType(event) {
        console.log('ModalCreateMaterial.js - handleChangeType');
        this.setState({ type: event.target.value });
    }

    handleChangeThickness(event) {
        console.log('ModalCreateMaterial.js - handleChangeThickness');
        var value = Number(event.target.value);
        if (!isNaN(value)) {
            this.setState({ thickness: event.target.value });
        }
        else {
            this.setState({ styleFormThickness: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormThickness: "" });
            }, 500);
        }
        //console.log(value);
    }

    handleChangeWidth(event) {
        console.log('ModalCreateMaterial.js - handleChangeWidth');
        var value = Number(event.target.value);
        if (!isNaN(value)) {
            this.setState({ width: event.target.value });
        }
        else {
            this.setState({ styleFormWidth: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormWidth: "" });
            }, 500);
        }
        //console.log(value);
    }


    render() {

        return (
            <div className={"row"}>
                <Modal show={this.props.showModal} onHide={this.modalTaggle.bind(this)} dialogClassName="add-tool-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>PŘIDÁNÍ NOVÉHO MATERIÁLU</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card.Body>
                            <Table >
                                <thead>
                                    <tr>
                                        <th width="50%">NÁZEV</th>
                                        <th width="50%">TYP</th>
                                        <th width="50%">TLOUŠŤKA [µm]</th>
                                        <th width="50%">ŠÍŘE [mm]</th>
                                        </tr>
                                </thead>
                                 <tbody>
                                    <tr>
                                        <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={this.state.name} onChange={(event) => this.handleChangeName(event)} /></td>
                                        <td><Form.Control className={this.state.styleFormType} style={{ width: '100%', textAlign: 'center' }} value={this.state.type} onChange={(event) => this.handleChangeType(event)} /></td>
                                        <td><Form.Control className={this.state.styleFormThickness} style={{ width: '100%', textAlign: 'center' }} value={this.state.thickness} onChange={(event) => this.handleChangeThickness(event)} /></td>
                                        <td><Form.Control className={this.state.styleFormWidth} style={{ width: '100%', textAlign: 'center' }} value={this.state.width} onChange={(event) => this.handleChangeWidth(event)} /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>

                        <Button className={"gfds"} style={{float: "right", marginRight: "10px" }} variant="success" onClick={this.handleAddButton.bind(this)} >PŘIDAT<img style={{ marginLeft: "10px"}} src={ImgAdd} alt="ImgAdd" width="20px" /></Button>
                        <Button className={"gfds"} style={{float: "right", marginRight: "10px" }} variant="secondary" onClick={this.handleCancelButton.bind(this)} >ZRUŠIT<img style={{ marginLeft: "10px"}} src={ImgCancel} alt="ImgCancel" width="20px" /></Button>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
