import React from 'react';
import DataStore from "../../stores/DataStore";
import ModalWrongOperation from './ModalWrongOperation';
import dateFormat from 'dateformat';
import ImgAdd from '../images/IconAdd.png';
import ImgEdit from '../images/IconEdit.png';
import ImgSave from '../images/IconSave.png';
import ImgCancel from '../images/IconCancel.png';
import ImgDelete from '../images/IconDelete.png';
// BOOTSTRAP
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
const pageName = ' EventLogs.js';

export default class EventLogs extends React.Component {
    constructor() {
        super();
        this.state = {
            loggedUser: {},
            eventLogsData: [],

        };


    }

    componentDidMount() {
        DataStore.on("getEventLogsComplete", this.getEventLogsComplete.bind(this));
        setTimeout(() => {
            DataStore.getEventLogs(this.props.terminal);
        }, 1000);

    }

    componentWillUnmount() {
        DataStore.removeListener("getEventLogsComplete", this.getEventLogsComplete);
        DataStore.removeListener("mysqlError", this.mysqlError);

    }
    

    getEventLogsComplete(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - getEventLogsComplete ");
            //console.log(result);
            this.setState({ eventLogsData: result });
        }
    }

    mysqlError(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - mysqlError ");
            console.log(result);
            this.setState({ showModalWrongOperation: true, textOfWrongOperation: JSON.stringify(result) });
        }
    }


    modalWrongOperationClose(event) {
        console.log('modalWrongOperationClose');
        this.setState({ showModalWrongOperation: false });
    }



    render() {
        var userCanEdit = DataStore.checkMinimumAuthorization(this.state.loggedUser, 'planner');
        
        return (
            <div className={"text-blue-white"}>
                <div className={"row"}>
                    <Card.Body>
                        <Table className="text-white">
                            <thead>
                                <tr>
                                    <th width="15%">KOMPONENTA</th>
                                    <th width="15%">AKCE</th>
                                    <th width="40%">TEXT</th>
                                    <th width="15%">DATUM</th>
                                    <th width="15%">UŽIVATEL</th>
                                </tr>
                            </thead>
                             <tbody>
                                {
                                    this.state.eventLogsData.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{item.event_log_component}</td>
                                                <td>{item.event_log_subcomponent}</td>
                                                <td>{item.event_log_text}</td>
                                                <td>{dateFormat(new Date(item.event_log_dt_occured), "yyyy-mm-dd HH:MM")}</td>
                                                <td>{item.user_name}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </div>
                <ModalWrongOperation showModal={this.state.showModalWrongOperation} textOfOperation={this.state.textOfWrongOperation} modalClose={this.modalWrongOperationClose.bind(this)}/>
            </div>
        );
    }
}
