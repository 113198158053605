import React from 'react';
import DataStore from "../../stores/DataStore";
import ModalDeleteConfirm from './ModalDeleteConfirm';
import ModalCreateCustomer from './ModalCreateCustomer';
import ModalWrongOperation from './ModalWrongOperation';
import ImgAdd from '../images/IconAdd.png';
import ImgEdit from '../images/IconEdit.png';
import ImgSave from '../images/IconSave.png';
import ImgCancel from '../images/IconCancel.png';
import ImgDelete from '../images/IconDelete.png';
// BOOTSTRAP
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
const pageName = ' Recipes.js';

export default class Customers extends React.Component {
    constructor() {
        super();
        this.handleEditButton = this.handleEditButton.bind(this);
        this.state = {
            customersData: [],
            editedId: 0,
            editedPrevName: '',
            showModalDelete: false,
            showModalCreate: false,
            showModalWrongOperation: false,
            textOfWrongOperation: '',

        };
    }

    componentDidMount() {
        DataStore.on("getCustomersComplete", this.getCustomersComplete.bind(this));
        DataStore.on("mysqlError", this.mysqlError.bind(this));
        setTimeout(() => {
            DataStore.getCustomers(this.props.terminal);
        }, 1000);

    }

    componentWillUnmount() {
        DataStore.removeListener("getCustomersComplete", this.getCustomersComplete);
        DataStore.removeListener("mysqlError", this.mysqlError);

    }

    getCustomersComplete(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - getCustomersComplete ");
            console.log(result);
            this.setState({ customersData: result });
        }
    }

    mysqlError(terminal, result) {
        if (this.props.terminal == terminal) {
            console.log(this.props.terminal + pageName + " - mysqlError ");
            console.log(result);
            this.setState({ showModalWrongOperation: true, textOfWrongOperation: JSON.stringify(result) });
        }
    }


    handleEditButton(event, editedId, editedName ) {
        console.log('handleEditButton');
        this.setState({ editedId: editedId });
        this.setState({ editedPrevName: editedName });
        console.log(editedId);

    }

    handleSaveButton(event) {
        console.log('handleSaveButton');
        var t = this;
        var name = "";
        this.state.customersData.forEach(function(customer) {
            if (customer.customer_id == t.state.editedId) {
                name = customer.customer_name;
            }
        })
        this.setState({ editedId: 0 });
        DataStore.updateCustomer(this.props.terminal, this.state.editedId, name );
    }

    handleCancelButton(event) {
        console.log('handleCancelButton');
        var t = this;

        var customersData = this.state.customersData;
        customersData.forEach(function(customer) {
            if (customer.customer_id == t.state.editedId) {
                customer.customer_name = t.state.editedPrevName;
            }
        })
        this.setState({ editedId: 0 });
        this.setState({ customersData: customersData });
        //console.log(event);
    }

    handleDeleteButton(event, deletingId) {
        console.log('handleDeleteButton');
        this.setState({ showModalDelete: true });
        this.setState({ deletingId: deletingId });
        console.log(event);
    }

    handleCreateButton(event) {
        console.log('handleCreateButton');
        this.setState({ showModalCreate: true });
        console.log(event);
    }

    handleChangeName(event) {
        console.log('handleChangeName');
        var t = this;
        var customersData = this.state.customersData;
        customersData.forEach(function(customer) {
            if (customer.customer_id == t.state.editedId) {
                customer.customer_name = event.target.value;
            }
        })
        this.setState({ customersData: customersData });
        console.log(event.target.value);
    }

    modalDeleteConfirmClose(event) {
        console.log('modalDeleteConfirmClose');
        this.setState({ showModalDelete: false });
    }

    modalDeleteConfirmCancel(event) {
        console.log('modalDeleteConfirmCancel');
        this.setState({ showModalDelete: false });
    }

    modalDeleteConfirmDelete(event) {
        console.log('modalDeleteConfirmDelete');
        this.setState({ showModalDelete: false });
        this.setState({ editedId: 0 });
        DataStore.deleteCustomer(this.props.terminal, this.state.deletingId);
        console.log(this.state.deletingId);
    }


    modalCreateClose(event) {
        console.log('modalCreateClose');
        this.setState({ showModalCreate: false });
    }

    modalCreateCancel(event) {
        console.log('modalCreateCancel');
        this.setState({ showModalCreate: false });
    }

    modalCreateAdd(name) {
        console.log('modalCreateAdd');
        this.setState({ showModalCreate: false });
        DataStore.createCustomer(this.props.terminal, name);
    }


    modalWrongOperationClose(event) {
        console.log('modalWrongOperationClose');
        this.setState({ showModalWrongOperation: false });
    }



    render() {
        return (
            <div className={"text-blue-white"}>
                <div className={"row"}>
                    <Card.Body>
                        <Table className="text-white">
                            <thead>
                                <tr>
                                    <th width="10%">ID</th>
                                    <th width="55%">NÁZEV</th>
                                    <th width="35%">AKCE <Button style={{float: "right", marginRight: "10px" }} variant="outline-success" onClick={this.handleCreateButton.bind(this)} >VYTVOŘIT<img style={{ marginLeft: "10px"}} src={ImgAdd} alt="ImgAdd" width="20px" /></Button></th>
                                    </tr>
                            </thead>
                             <tbody>
                                {
                                    this.state.customersData.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{item.customer_id}</td>
                                                {this.state.editedId==item.customer_id
                                                    ? <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={item.customer_name} onChange={(event) => this.handleChangeName(event)} /></td>
                                                    : <td>{item.customer_name}</td>
                                                }
                                                <td>
                                                    <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-danger" onClick={(event) => this.handleDeleteButton(event, item.customer_id)} >SMAZAT<img style={{ marginLeft: "10px"}} src={ImgDelete} alt="ImgDelete" width="20px" /></Button>
                                                    {this.state.editedId==0
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-warning" onClick={(event) => this.handleEditButton(event, item.customer_id, item.customer_name )}>EDITOVAT<img style={{ marginLeft: "10px"}} src={ImgEdit} alt="ImgEdit" width="20px" /></Button>
                                                        : null
                                                    }
                                                    {this.state.editedId==item.customer_id
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-warning" onClick={this.handleCancelButton.bind(this)} >ZRUŠIT<img style={{ marginLeft: "10px"}} src={ImgCancel} alt="ImgCancel" width="20px" /></Button>
                                                        : null
                                                    }
                                                    {this.state.editedId==item.customer_id
                                                        ? <Button className={"gfds"} style={{float: "left", marginRight: "10px" }} variant="outline-success" onClick={this.handleSaveButton.bind(this)} >ULOŽIT<img style={{ marginLeft: "10px"}} src={ImgSave} alt="ImgSave" width="20px" /></Button>
                                                        : null
                                                    }
                                                    
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </div>
                <ModalDeleteConfirm showModal={this.state.showModalDelete} modalClose={this.modalDeleteConfirmClose.bind(this)} modalCancel={this.modalDeleteConfirmCancel.bind(this)} modalDelete={this.modalDeleteConfirmDelete.bind(this)} />
                <ModalCreateCustomer showModal={this.state.showModalCreate} modalClose={this.modalCreateClose.bind(this)} modalCancel={this.modalCreateCancel.bind(this)} modalAdd={this.modalCreateAdd.bind(this)} />
                <ModalWrongOperation showModal={this.state.showModalWrongOperation} textOfOperation={this.state.textOfWrongOperation} modalClose={this.modalWrongOperationClose.bind(this)}/>
            </div>
        );
    }
}
