import React from 'react';
import DataStore from "../../stores/DataStore";
import dateFormat from 'dateformat';
import Webcam from "react-webcam";

import ImgAdd from '../images/IconAdd.png';
import ImgCancel from '../images/IconCancel.png';
import ImgCam from '../images/Cam.png';
// BOOTSTRAP
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const pageName = ' ModalJobCreate.js';
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();



export default class ModalJobQualityCheck extends React.Component {
    constructor() {
        super();
        this.state = {
        }

    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    modalTaggle() {
        console.log(pageName + " - modalTaggle ");
        this.props.modalClose();
    }

    modalShow() {
        console.log(pageName + " - modalShow ");
        var job_name = dateFormat(new Date(), "yyyy") + ' / ' + this.props.newJobIndex;
        this.setState({ recipesDataFiltered: this.props.recipesData, job_name });
    }

    buttonClick() {
        //console.log(this.props.job);
        //Webcam.getScreenshot();
        var img = this.refs.webcam.getScreenshot();
        this.props.saveTestStationPicture(this.props.job, img);
        this.props.modalClose();
    }


    render() {
        return (
            <div className={"row"}>
                <Modal show={this.props.showModal} onShow={this.modalShow.bind(this)} onHide={this.modalTaggle.bind(this)} dialogClassName="add-job-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>VYTVOŘENÍ TESTU KVALITY - Kamera</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        NOVÝ SNÍMEK Z KAMERY
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Webcam
                                            screenshotFormat="image/jpeg"
                                            audio={false}
                                            width={640}
                                            height={480}
                                            ref='webcam'
                                            videoConstraints={{width: 640, height: 480}}
                                        >
                                        </Webcam>
                                        <Button onClick={this.buttonClick.bind(this)} className={"gfds"} style={{float: "right", marginRight: "10px" }} variant="success" >VYTVOŘIT<img style={{ marginLeft: "10px"}} src={ImgAdd} alt="ImgAdd" width="20px" /></Button>

                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        SNÍMKY Z HISTORIE ({this.props.imagesHistory.length})
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        {this.props.imagesHistory.map((image, index) => {
                                        return <Card style={{ width: '50%', margin: '10px' }}>
                                              <Card.Img variant="top" src={"data:image/png;base64, " + image.content } />
                                              <Card.Body>
                                                <Card.Title>{image.name}</Card.Title>
                                                <Card.Text>
                                                </Card.Text>
                                              </Card.Body>
                                            </Card>
                                        })}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
