import React from 'react';
import ImgAdd from '../images/IconAdd.png';
import ImgCancel from '../images/IconCancel.png';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalCreateRecipe extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            recipe_tool_id: 0,
            recipe_material_id: 0,
            recipe_parts_per_kilogram: 0,
            recipe_parts_per_shift: 0,
            recipe_parts_per_package: 0,
            recipe_cardboard: '',
            recipe_divider: '',


            styleFormPartsPerKilogram: '',
            styleFormPartsPerShift: '',
            styleFormPartsPerPackage: '',

            toolSearchFilter: '',
            materialSearchFilter: '',
            toolsDataFiltered: [],
            materialsDataFiltered: [],

        }

    }

    componentDidMount() {}

    componentWillUnmount() {}

    modalTaggle() {
        console.log("ModalCreateRecipe.js - modalTaggle ");
        this.props.modalClose();
    }

    modalShow() {
        console.log("ModalCreateRecipe.js - modalShow ");
        this.setState({ toolsDataFiltered: this.props.toolsData });
        this.setState({ materialsDataFiltered: this.props.materialsData });
    }

    handleAddButton() {
        console.log("ModalCreateRecipe.js - handleAddButton ");
        this.props.modalAdd(this.state.name, this.state.recipe_tool_id, this.state.recipe_material_id, this.state.recipe_parts_per_kilogram, this.state.recipe_parts_per_shift, this.state.recipe_parts_per_package, this.state.recipe_cardboard, this.state.recipe_divider);
    }

    handleCancelButton() {
        console.log("ModalCreateRecipe.js - handleCancelButton ");
        this.props.modalCancel();
    }


    handleChangeName(event) {
        console.log('ModalCreateRecipe.js - handleChangeName');
        this.setState({ name: event.target.value });
        console.log(event.target.value);
    }

    handleChangeTool(id) {
        console.log('ModalCreateRecipe.js - handleChangeTool');
        this.setState({ recipe_tool_id: id });
    }

    handleChangeMaterial(id) {
        console.log('ModalCreateRecipe.js - handleChangeMaterial');
        this.setState({ recipe_material_id: id });
    }

    handleChangePartsPerKilogram(event) {
        console.log('handleChangePartsPerKilogram');
        var t = this;
        var value = Number(event.target.value);
        if (!isNaN(value)) {
            this.setState({ recipe_parts_per_kilogram: event.target.value });
        }
        else {
            this.setState({ styleFormPartsPerKilogram: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormPartsPerKilogram: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangePartsPerShift(event) {
        console.log('handleChangePartsPerShift');
        var t = this;
        var value = Number(event.target.value);
        if (!isNaN(value)) {
            this.setState({ recipe_parts_per_shift: event.target.value });
        }
        else {
            this.setState({ styleFormPartsPerShift: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormPartsPerShift: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangePartsPerPackage(event) {
        console.log('handleChangePartsPerPackage');
        var t = this;
        var value = Number(event.target.value);
        if (!isNaN(value)) {
            this.setState({ recipe_parts_per_package: event.target.value });
        }
        else {
            this.setState({ styleFormPartsPerPackage: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormPartsPerPackage: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangeCardboard(event) {
        console.log('ModalCreateRecipe.js - handleChangeCardboard');
        this.setState({ recipe_cardboard: event.target.value });
        console.log(event.target.value);
    }

    handleChangeDivider(event) {
        console.log('ModalCreateRecipe.js - handleChangeDivider');
        this.setState({ recipe_divider: event.target.value });
        console.log(event.target.value);
    }



    getToolName(id) {
        var name = "NEVYBRÁNO";
        this.props.toolsData.forEach(function(tool) {
            if (tool.tool_id == id) {
                name = tool.tool_name;
            }
        })
        return name;
    }

    refreshToolFilter(event) {
        var value = event.target.value;
        var toolsDataFiltered = [];
        this.setState({ toolSearchFilter: value });
        this.props.toolsData.forEach(function(tool) {
            if (String(tool.tool_name).toUpperCase().search(value.toUpperCase()) >= 0) {
                toolsDataFiltered.push(tool);
            }
        })
        this.setState({ toolsDataFiltered });
    }

    getMaterialName(id) {
        var name = "NEVYBRÁNO";
        this.props.materialsData.forEach(function(material) {
            if (material.material_id == id) {
                name = material.material_type + ' ' + material.material_width + 'mm x ' + material.material_thickness + 'µm';
            }
        })
        return name;
    }

    refreshMaterialFilter(event) {
        var value = event.target.value;
        var materialsDataFiltered = [];
        this.setState({ materialSearchFilter: value });
        this.props.materialsData.forEach(function(material) {
            if (String(material.material_name).toUpperCase().search(value.toUpperCase()) >= 0 || String(material.material_type).toUpperCase().search(value.toUpperCase()) >= 0 || String(material.material_width).toUpperCase().search(value.toUpperCase()) >= 0 || String(material.material_thickness).toUpperCase().search(value.toUpperCase()) >= 0) {
                materialsDataFiltered.push(material);
            }
        })
        this.setState({ materialsDataFiltered });
    }



    render() {

        return (
            <div className={"row"}>
                <Modal show={this.props.showModal} onShow={this.modalShow.bind(this)} onHide={this.modalTaggle.bind(this)} dialogClassName="add-recipe-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>PŘIDÁNÍ NOVÉ RECEPTURY</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card.Body>
                            <Table >
                                <thead>
                                    <tr>
                                        <th width="10%">NÁZEV</th>
                                        <th width="10%">FORMA</th>
                                        <th width="10%">MATERIÁL</th>
                                        <th width="10%">KS/KG</th>
                                        <th width="5%">KS/SMĚNU</th>
                                        <th width="5%">KS/BALENÍ</th>
                                        <th width="5%">KARTON</th>
                                        <th width="5%">ROZDĚLOVNÍK</th>
                                    </tr>
                                </thead>
                                 <tbody>
                                    <tr>
                                        <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={this.state.name} onChange={(event) => this.handleChangeName(event)} /></td>

                                        <td>
                                            <DropdownButton variant="light"  title={this.getToolName(this.state.recipe_tool_id)}   >
                                                <Form.Control className={"dropdown-menu-search"} value={this.state.toolSearchFilter} onChange={(event) => this.refreshToolFilter(event)} />
                                                    {
                                                    this.state.toolsDataFiltered.map((tool, tool_key) => {
                                                        return (
                                                            <Dropdown.Item as="button" eventKey={tool.tool_id} key={tool.tool_id} onSelect={this.handleChangeTool.bind(this)}>{tool.tool_name}</Dropdown.Item>
                                                        )
                                                    })}
                                            </DropdownButton>
                                        </td>

                                        <td>
                                            <DropdownButton variant="light"  title={this.getMaterialName(this.state.recipe_material_id)}   >
                                                <Form.Control className={"dropdown-menu-search"} value={this.state.materialSearchFilter} onChange={(event) => this.refreshMaterialFilter(event)} />
                                                    {
                                                    this.state.materialsDataFiltered.map((material, material_key) => {
                                                        return (
                                                            <Dropdown.Item as="button" eventKey={material.material_id} key={material.material_id} onSelect={this.handleChangeMaterial.bind(this)}>{this.getMaterialName(material.material_id)}</Dropdown.Item>
                                                        )
                                                    })}
                                            </DropdownButton>
                                        </td>
                                        
                                        <td><Form.Control className={this.state.styleFormPartsPerKilogram} style={{ width: '100%', textAlign: 'center' }} value={this.state.recipe_parts_per_kilogram} onChange={(event) => this.handleChangePartsPerKilogram(event)} /></td>
                                        
                                        <td><Form.Control className={this.state.styleFormPartsPerShift} style={{ width: '100%', textAlign: 'center' }} value={this.state.recipe_parts_per_shift} onChange={(event) => this.handleChangePartsPerShift(event)} /></td>
                                        
                                        <td><Form.Control className={this.state.styleFormPartsPerPackage} style={{ width: '100%', textAlign: 'center' }} value={this.state.recipe_parts_per_package} onChange={(event) => this.handleChangePartsPerPackage(event)} /></td>
                                        
                                        <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={this.state.recipe_cardboard} onChange={(event) => this.handleChangeCardboard(event)} /></td>
                                        
                                        <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={this.state.recipe_divider} onChange={(event) => this.handleChangeDivider(event)} /></td>



                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>

                        <Button className={"gfds"} style={{float: "right", marginRight: "10px" }} variant="success" onClick={this.handleAddButton.bind(this)} >PŘIDAT<img style={{ marginLeft: "10px"}} src={ImgAdd} alt="ImgAdd" width="20px" /></Button>
                        <Button className={"gfds"} style={{float: "right", marginRight: "10px" }} variant="secondary" onClick={this.handleCancelButton.bind(this)} >ZRUŠIT<img style={{ marginLeft: "10px"}} src={ImgCancel} alt="ImgCancel" width="20px" /></Button>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
