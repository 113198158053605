import React from 'react';
import dateFormat from 'dateformat';
import ImgAdd from '../images/IconAdd.png';
import ImgCancel from '../images/IconCancel.png';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const pageName = ' ModalJobEdit.js';

//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default class ModalCreateEdit extends React.Component {
    constructor() {
        super();
        this.state = {
            job_name: "",
            job_recipe_id: 0,
            job_boxes: 0,
            job_machine_id: 1,
            job_date_plan: new Date(),
            job_time_plan: "00:00",
            job_note: "",
            job_divider_note: "",



            styleFormParts: '',
            styleFormBoxes: '',

            recipeSearchFilter: '',
            recipesDataFiltered: [],

        }

    }

    componentDidMount() {}

    componentWillUnmount() {}

    modalTaggle() {
        console.log(pageName + " - modalTaggle ");
        this.props.modalClose();
    }

    modalShow() {
        console.log(pageName + " - modalShow ");
        this.setState({
            recipesDataFiltered: this.props.recipesData,
            job_name: this.props.job.job_name,
            job_recipe_id: this.props.job.job_recipe_id,
            job_boxes: this.props.job.job_boxes,
            job_machine_id: this.props.job.job_machine_id,
            job_date_plan: new Date(this.props.job.job_dt_plan.slice(0, 19)),
            job_time_plan: dateFormat(this.props.job.job_dt_plan.slice(0, 19), 'HH:MM'),
            job_note: this.props.job.job_note,
            job_divider_note: this.props.job.job_divider_note,
        });
    }

    handleSaveButton() {
        console.log(pageName + " - handleSaveButton ");
        this.props.modalSave(this.props.job.job_id, this.props.job.job_name, this.state.job_recipe_id, this.state.job_machine_id, this.state.job_boxes, dateFormat(this.state.job_date_plan, 'yyyy-mm-dd') + " " + this.state.job_time_plan, this.state.job_note, this.state.job_divider_note);
    }

    handleCancelButton() {
        console.log(pageName + " - handleCancelButton ");
        this.props.modalClose();
    }


    handleChangeName(event) {
        console.log(pageName + " - handleChangeName");
        this.setState({ job_name: event.target.value });
        console.log(event.target.value);
    }

    handleChangeNote(event) {
        console.log(pageName + " - handleChangeNote");
        this.setState({ job_note: event.target.value });
        //console.log(event.target.value);
    }

    handleChangeDividerNote(event) {
        console.log(pageName + " - handleChangeDividerNote");
        this.setState({ job_divider_note: event.target.value });
        //console.log(event.target.value);
    }

    handleChangeParts(event) {
        console.log('handleChangeParts');
        var t = this;
        var value = Number(event.target.value);
        if (!isNaN(value)) {
            this.setState({ job_parts: event.target.value });
        }
        else {
            this.setState({ styleFormParts: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormParts: "" });
            }, 500);
        }
        console.log(value);
    }

    handleChangeBoxes(event) {
        console.log('handleChangeBoxes');
        var t = this;
        var value = Number(event.target.value);
        if (!isNaN(value)) {
            this.setState({ job_boxes: event.target.value });
        }
        else {
            this.setState({ styleFormBoxes: "blinkingWrong" });
            setTimeout(() => {
                this.setState({ styleFormBoxes: "" });
            }, 500);
        }
        console.log(value);
    }


    handleChangeRecipe(id) {
        console.log(pageName + " - handleChangeRecipe");
        this.setState({ job_recipe_id: id });
        console.log(id);
    }

    handleChangeMachine(key) {
        console.log(pageName + " - handleChangeMachine");
        this.setState({ job_machine_id: key });
        console.log(key);
    }

    handleChangeDate(date) {
        console.log(pageName + " - handleChangeDate");
        console.log(date);
        this.setState({ job_date_plan: date });
    }

    handleChangeTime(event) {
        console.log(pageName + " - handleChangeTime");
        console.log(event.target.value);
        this.setState({ job_time_plan: event.target.value });
    }


    getRecipeName(id) {
        var name = "NEVYBRÁNO";
        this.props.recipesData.forEach(function(recipe) {
            if (recipe.recipe_id == id) {
                name = recipe.recipe_name + ' | ' + recipe.tool_name + ' | ' + recipe.material_type + ' ' + recipe.material_width + 'mm x ' + recipe.material_thickness + 'µm';
            }
        })
        return name;
    }

    refreshRecipeFilter(event) {
        var value = event.target.value;
        var recipesDataFiltered = [];
        this.setState({ recipeSearchFilter: value });
        this.props.recipesData.forEach(function(recipe) {
            if (String(recipe.tool_name).toUpperCase().search(value.toUpperCase()) >= 0 || String(recipe.recipe_name).toUpperCase().search(value.toUpperCase()) >= 0) {
                recipesDataFiltered.push(recipe);
            }
        })
        this.setState({ recipesDataFiltered });
    }



    render() {

        return (
            <div className={"row"}>
                <Modal show={this.props.showModal} onShow={this.modalShow.bind(this)} onHide={this.modalTaggle.bind(this)} dialogClassName="add-job-modal" style={{color: "#222c40"}}>
                    <Modal.Header closeButton>
                      <Modal.Title>EDITACE JOBU</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card.Body>
                            <Table style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th width="15%">NÁZEV</th>
                                        <th width="30%">RECEPTURA</th>
                                        <th width="20%">PŘIŘAZENÝ STROJ</th>
                                        <th width="10%">KARTONŮ</th>
                                        <th width="25%">PLÁNOVANÝ START</th>
                                    </tr>
                                </thead>
                                 <tbody>
                                    <tr>
                                        <td><Form.Control style={{ width: '100%', textAlign: 'center' }} value={this.state.job_name} onChange={(event) => this.handleChangeName(event)} /></td>

                                        <td>
                                            <DropdownButton variant="light"  title={this.getRecipeName(this.state.job_recipe_id)}   >
                                                <Form.Control className={"dropdown-menu-search"} value={this.state.recipeSearchFilter} onChange={(event) => this.refreshRecipeFilter(event)} />
                                                    {
                                                    this.state.recipesDataFiltered.map((recipe, recipe_key) => {
                                                        return (
                                                            <Dropdown.Item as="button" eventKey={recipe.recipe_id} key={recipe.recipe_id} onSelect={this.handleChangeRecipe.bind(this)}>{this.getRecipeName(recipe.recipe_id)}</Dropdown.Item>
                                                        )
                                                    })}
                                            </DropdownButton>
                                        </td>

                                        <td>
                                            <DropdownButton variant="light"  title={"M" + this.state.job_machine_id}   >
                                                <Dropdown.Item as="button" eventKey={"1"} key={1} onSelect={this.handleChangeMachine.bind(this)}>{"M1"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"2"} key={2} onSelect={this.handleChangeMachine.bind(this)}>{"M2"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"3"} key={3} onSelect={this.handleChangeMachine.bind(this)}>{"M3"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"4"} key={4} onSelect={this.handleChangeMachine.bind(this)}>{"M4"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"5"} key={5} onSelect={this.handleChangeMachine.bind(this)}>{"M5"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"6"} key={6} onSelect={this.handleChangeMachine.bind(this)}>{"M6"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"7"} key={7} onSelect={this.handleChangeMachine.bind(this)}>{"M7"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"8"} key={8} onSelect={this.handleChangeMachine.bind(this)}>{"M8"}</Dropdown.Item>
                                                <Dropdown.Item as="button" eventKey={"9"} key={9} onSelect={this.handleChangeMachine.bind(this)}>{"M9"}</Dropdown.Item>
                                            </DropdownButton>
                                        </td>

                                        <td><Form.Control className={this.state.styleFormBoxes} style={{ width: '100%', textAlign: 'center' }} value={this.state.job_boxes} onChange={(event) => this.handleChangeBoxes(event)} /></td>
                                        
                                        
                                        <td>
                                            <Form.Control as={DatePicker}
                                                className={"text-black form-control"}
                                                dateFormat="yyyy-MM-dd"
                                                selected={this.state.job_date_plan}
                                                onChange={this.handleChangeDate.bind(this)}
                                            />
                                            <input type="time" id="appt" name="appt" value={this.state.job_time_plan} onChange={this.handleChangeTime.bind(this)}  />
                                        </td>

                                    </tr>
                                </tbody>
                            </Table>
                            
                            <Table style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th width="50%">POZNÁMKA</th>
                                        <th width="50%">ROZDĚLOVNÍK</th>
                                    </tr>
                                </thead>
                                 <tbody>
                                    <tr>
                                        <td><Form.Control as="textarea" rows={3} style={{ width: '100%', textAlign: 'center' }} value={this.state.job_note} onChange={(event) => this.handleChangeNote(event)} /></td>
                                        <td><Form.Control as="textarea" rows={3} style={{ width: '100%', textAlign: 'center' }} value={this.state.job_divider_note} onChange={(event) => this.handleChangeDividerNote(event)} /></td>
                                    </tr>
                                 </tbody>
                            </Table>

                        </Card.Body>

                        <Button className={"gfds"} style={{float: "right", marginRight: "10px" }} variant="success" onClick={this.handleSaveButton.bind(this)} >ULOŽIT<img style={{ marginLeft: "10px"}} src={ImgAdd} alt="ImgAdd" width="20px" /></Button>
                        <Button className={"gfds"} style={{float: "right", marginRight: "10px" }} variant="secondary" onClick={this.handleCancelButton.bind(this)} >ZRUŠIT<img style={{ marginLeft: "10px"}} src={ImgCancel} alt="ImgCancel" width="20px" /></Button>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
